import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tag, Tooltip } from 'antd';

import { accountList, accountSync } from '@src/clients/3thix';
import { CRYPTO_CURRENCY_LIST } from '@src/constants';
import { Account, Error3thix, SuccessAccountList } from '@src/types';

import ModalCreateAccount from './components/ModalCreateAccount';
import ModalTransfer from './components/ModalTransfer';
import ModalUpdateAccount from './components/ModalUpdateAccount';
import ModalViewBalance from './components/ModalViewBalance';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  entityID: string;
};

const AccountsTable = ({ entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [accountsResp, setAccountsResp] = useState<SuccessAccountList | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [accountCurrencyId, setAccountCurrencyId] = useState<string>();
  const [openBalanceModal, setOpenBalanceModal] = useState(false);

  const getAccounts = useCallback(async () => {
    const { data, status } = await accountList(page, pageSize, entityID);

    if (status === 200) {
      setAccountsResp(data as SuccessAccountList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, entityID]);

  const toggleCreateModal = () => setOpenCreateModal(!openCreateModal);
  const toggleUpdateModal = useCallback(() => {
    setOpenUpdateModal((prev) => !prev);
  }, []);

  const toggleTransferModal = useCallback(() => {
    setOpenTransferModal((prev) => !prev);
  }, []);

  const showBalanceModal = (accountCurrencyId: string) => {
    setAccountCurrencyId(accountCurrencyId);
    setOpenBalanceModal(true);
  };

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const refreshAccounts = useCallback(() => {
    getAccounts();
  }, [getAccounts]);

  const rows = useMemo(() => {
    if (accountsResp === null) {
      return [];
    }

    return accountsResp.accounts;
  }, [accountsResp]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: Account) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="View balance">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-wallet" />}
                onClick={() => showBalanceModal(record.account_currency_id)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setSelectedAccount(record);
                  toggleUpdateModal();
                }}
              />
            </Tooltip>
            {CRYPTO_CURRENCY_LIST[record.currency] && (
              <>
                <Tooltip title="Sync balance">
                  <Button
                    type="default"
                    size="middle"
                    icon={<i className="fas fa-refresh" />}
                    onClick={async () => {
                      const { status } = await accountSync(record.account_currency_id);
                      if (status === 201) {
                        message.info('Start sync balance of your account');
                      }
                    }}
                  />
                </Tooltip>
                <Tooltip title="Transfer">
                  <Button
                    type="default"
                    size="middle"
                    icon={<i className="fas fa-exchange-alt" />}
                    onClick={() => {
                      setSelectedAccount(record);
                      toggleTransferModal();
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Flex>
        ),
      },
      {
        title: 'Account Roles',
        dataIndex: 'roles',
        key: 'roles',
        render: (roles: { type: string; is_default: boolean }[]) => {
          return roles.map((role) => (
            <Tag key={role.type} color={role.is_default ? 'green' : 'blue'}>
              {role.type} {role.is_default ? '(Default)' : ''}
            </Tag>
          ));
        },
      },
      ...COLUMNS,
    ];
  }, [setSelectedAccount, toggleUpdateModal, toggleTransferModal]);

  return (
    <div className="text-right">
      <Button
        type="default"
        size="large"
        icon={<i className="fas fa-plus" />}
        style={{ marginBottom: '20px' }}
        onClick={toggleCreateModal}
      >
        New Account
      </Button>

      <ModalCreateAccount
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onRefresh={refreshAccounts}
      />

      <ModalUpdateAccount
        account={selectedAccount}
        isOpen={openUpdateModal}
        toggleOpen={toggleUpdateModal}
        onRefresh={getAccounts} // Assuming getAccounts is your function to refresh the list
      />

      {accountCurrencyId && (
        <ModalViewBalance
          accountCurrencyId={accountCurrencyId}
          isOpen={openBalanceModal}
          toggleOpen={() => setOpenBalanceModal(!openBalanceModal)}
        />
      )}

      <ModalTransfer
        account={selectedAccount}
        isOpen={openTransferModal}
        toggleOpen={toggleTransferModal}
        onRefresh={getAccounts} // Assuming getAccounts is your function to refresh the list
      />

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && accountsResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: accountsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AccountsTable;
