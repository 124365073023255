import { useMemo } from 'react';

import { Breadcrumb as BreadcrumbAntd } from 'antd';

import { Breadcrumb } from './types';

type Props = {
  breadcrumbs: Breadcrumb[];
};

const Breadcrumbs = ({ breadcrumbs }: Props) => {
  const items = useMemo(
    () =>
      breadcrumbs.map((value, index) => ({
        key: index,
        href: value.path,
        title: value.name,
      })),
    [breadcrumbs]
  );

  return (
    <div className="w-max text-left mb-5">
      <BreadcrumbAntd items={items} />
    </div>
  );
};

export default Breadcrumbs;
