import { useCallback, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { processorConfigCreate, processorConfigUpdate } from '@src/clients/3thix';
import { CreateProcessorConfigReq, Error3thix } from '@src/types';

type Props = {
  oldConfigs?: {
    id: string;
    merchant_id: string;
    merchant_site_id: string;
  };
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onSuccess: () => void;
};

const ModalCreateUpdate = ({ toggleOpen, isOpen, entityID, onSuccess, oldConfigs }: Props) => {
  const [formData, setFormData] = useState<CreateProcessorConfigReq>({
    parent_id: null,
    processor_entity_id: entityID,
    config: {
      merchant_id: oldConfigs ? oldConfigs.merchant_id : '',
      merchant_site_id: oldConfigs ? oldConfigs.merchant_site_id : '',
      merchant_secret_key: '',
    },
  });

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (oldConfigs) {
        const { status, data } = await processorConfigUpdate({
          id: oldConfigs.id,
          parent_id: null,
          config: {
            merchant_id: formData.config.merchant_id,
            merchant_site_id: formData.config.merchant_site_id,
            merchant_secret_key: formData.config.merchant_secret_key,
          },
        });
        if (status !== 204) {
          message.error((data as Error3thix).message);
          return;
        }
      } else {
        const { status, data } = await processorConfigCreate(formData);
        if (status !== 201) {
          message.error((data as Error3thix).message);
          return;
        }
      }

      onSuccess();
    },
    [formData, oldConfigs, onSuccess]
  );

  return (
    <Modal open={isOpen} footer={null} title="Processor Config" onOk={toggleOpen} onCancel={toggleOpen}>
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Merchant ID (nuvei)
            </label>

            <Input
              required
              size="large"
              type="text"
              name="merchant_id"
              defaultValue={oldConfigs ? oldConfigs.merchant_id : ''}
              className="my-2 w-full"
              onChange={(event) =>
                setFormData((old) => ({ ...old, config: { ...old.config, merchant_id: event.target.value } }))
              }
            />
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Merchant Site ID (nuvei)
            </label>

            <Input
              required
              size="large"
              type="text"
              name="merchant_site_id"
              defaultValue={oldConfigs ? oldConfigs.merchant_site_id : ''}
              className="my-2 w-full"
              onChange={(event) =>
                setFormData((old) => ({ ...old, config: { ...old.config, merchant_site_id: event.target.value } }))
              }
            />
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Merchant Secret (nuvei)
            </label>

            <Input
              required
              size="large"
              type="text"
              name="merchant_secret_key"
              className="my-2 w-full"
              onChange={(event) =>
                setFormData((old) => ({ ...old, config: { ...old.config, merchant_secret_key: event.target.value } }))
              }
            />
          </div>
          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            {oldConfigs ? 'Update' : 'Create'}
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateUpdate;
