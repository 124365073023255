import { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Checkbox, message, Segmented, Skeleton, Table } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import Title from 'antd/es/typography/Title';

import { SearchOutlined } from '@ant-design/icons';
import { userList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { searchTextFilterTable } from '@src/components/SearchFilterTable';
import { THEME } from '@src/theme';
import { Error3thix, SuccessUserList } from '@src/types';

import { PAGE_SIZE } from './constants';

const SearchIcon = (filtered: boolean) => (
  <SearchOutlined style={{ color: filtered ? THEME.token?.colorPrimary : undefined }} />
);

const Users = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [onlyAdmin, setOnlyAdmin] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [usersResp, setUsersResp] = useState<SuccessUserList | null>(null);

  const getUsers = useCallback(async () => {
    const { data, status } = await userList(page, pageSize, null, searchText, onlyAdmin);

    if (status === 200) {
      setUsersResp(data as SuccessUserList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, searchText, onlyAdmin]);

  const handleAdminSearch = useCallback((key: SegmentedValue) => {
    switch (key) {
      case 'only_admin':
        setOnlyAdmin(true);
        break;
      case 'except_admin':
        setOnlyAdmin(false);
        break;
      default:
        setOnlyAdmin(null);
        break;
    }
  }, []);

  const columns = useMemo(
    () => [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Phone', dataIndex: 'phone' },
      {
        title: 'Email',
        dataIndex: 'email',
        filterDropdown: searchTextFilterTable({
          setSearchText: setSearchText,
          fieldName: 'Email',
        }),
        filterIcon: SearchIcon,
      },
      { title: 'Xsolla User ID', dataIndex: 'xsolla_user_id' },
      { title: 'ID', dataIndex: 'id' },
      { title: 'Admin', dataIndex: 'is_admin' },
    ],
    []
  );

  const rows = useMemo(() => {
    if (usersResp === null) {
      return [];
    }

    return usersResp.users.map((user) => {
      return {
        id: user.id,
        kyc_approved: user.kyc_approved ? 'Yes' : 'No',
        name: `${user.first_name} ${user.last_name}`,
        phone: user.phone,
        email: user.email,
        xsolla_user_id: user.xsolla_user_id,
        is_admin: <Checkbox key={`checkbox-${user.id}`} checked={user.is_admin}></Checkbox>,
      };
    });
  }, [usersResp]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onRow = useCallback(
    (record: { id: string }) => ({
      onClick: () => {
        navigate(`/users/${record.id}`);
      },
    }),
    [navigate]
  );

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-solid fa-users"></i> Users
          </Title>
        </div>
      </div>

      <div>{isLoading && <Skeleton />}</div>

      <div>
        {!isLoading && usersResp !== null && (
          <div>
            <div className="justify-center flex mb-5">
              <Segmented
                defaultValue="all"
                options={[
                  {
                    label: 'All',
                    value: 'all',
                  },
                  {
                    label: 'Only Admin',
                    value: 'only_admin',
                  },
                  {
                    label: 'Except Admin',
                    value: 'except_admin',
                  },
                ]}
                onChange={handleAdminSearch}
              />
            </div>
            <Table
              dataSource={rows}
              columns={columns}
              scroll={{ x: '50%' }}
              pagination={{
                position: ['bottomCenter'],
                defaultCurrent: page,
                total: usersResp.total,
                defaultPageSize: pageSize,
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                },
                onChange: setPage,
              }}
              onRow={onRow}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Users;
