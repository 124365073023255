import { useCallback, useMemo, useState } from 'react';

import { App, Button, Input, message, Modal } from 'antd';

import { campaignCreate } from '@src/clients/3thix';
import { CreateCampaignReq, Error3thix } from '@src/types';

type Props = {
  toggleOpen: () => void;
  onSuccess: () => void;
  isOpen: boolean;
};

const defaultValue = {
  name: '',
  external_campaign_id: '',
};

const ModalCreate = ({ toggleOpen, isOpen, onSuccess }: Props) => {
  const { modal } = App.useApp();
  const [formData, setFormData] = useState<CreateCampaignReq>(defaultValue);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await campaignCreate(formData);
      if (status === 201) {
        modal.success({
          title: 'Campaign successfully created',
          onOk: () => {
            setFormData(defaultValue);
            onSuccess();
          },
          footer: (_, { OkBtn }) => <OkBtn />,
        });
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, modal, onSuccess]
  );

  const inputs = useMemo(() => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
    return Object.keys(defaultValue).map((key) => (
      <div key={`index-${key}`} className="flex flex-col w-full">
        <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
          {key.replaceAll('_', ' ')}
        </label>
        <Input required size="large" type="text" name={key} className="my-2 w-full" onChange={handleChange} />
      </div>
    ));
  }, []);

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-money-bill-transfer"></i> New Campaign
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="mt-8 flex flex-row w-full flex-wrap justify-between">{inputs}</div>

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreate;
