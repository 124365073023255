import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';

import { notificationHistoryList } from '@src/clients/3thix';
import { searchRangeDateFilterTable, searchTextFilterTable } from '@src/components/SearchFilterTable';
import { Error3thix, ListNotificationHistoriesReq, ListNotificationHistoriesResp } from '@src/types';

import { DEFAULT_SEARCH_VALUES, PAGE_SIZE } from './constants';

const History = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [webhooksResp, setWebhooksResp] = useState<ListNotificationHistoriesResp>();
  const [search, setSearch] = useState<ListNotificationHistoriesReq>({ search_by: DEFAULT_SEARCH_VALUES });

  const getAudits = useCallback(async () => {
    const { data, status } = await notificationHistoryList(page, pageSize, search);

    if (status === 200) {
      setWebhooksResp(data as ListNotificationHistoriesResp);
    } else {
      message.error((data as Error3thix).message);
    }

    setIsLoading(false);
  }, [page, pageSize, search]);

  const onChangeSort = useCallback((field: string) => {
    setSearch((old) => {
      if (old.order_by && old.order_by.field === field) {
        return { ...old, order_by: old.order_by.order === 'DESC' ? { field, order: 'ASC' } : undefined };
      }

      return { ...old, order_by: { field, order: 'DESC' } };
    });
  }, []);

  useEffect(() => {
    getAudits();
  }, [getAudits]);

  const rows = useMemo(() => (webhooksResp ? webhooksResp.histories : []), [webhooksResp]);

  const columns = useMemo(
    () => [
      {
        title: 'Event',
        dataIndex: 'notification_event',
        filterDropdown: searchTextFilterTable({
          setSearchText: (notification_event) =>
            setSearch((old) => {
              old.search_by.notification_event = notification_event === null ? undefined : notification_event;
              return { ...old };
            }),
          fieldName: 'Event',
        }),
      },
      {
        title: 'Entity Name',
        dataIndex: 'entity_name',
        filterDropdown: searchTextFilterTable({
          setSearchText: (entity_name) =>
            setSearch((old) => {
              old.search_by.entity_name = entity_name === null ? undefined : entity_name;
              return { ...old };
            }),
          fieldName: 'Entity Name',
        }),
      },
      {
        title: 'Entity Type',
        dataIndex: 'entity_type',
        filterDropdown: searchTextFilterTable({
          setSearchText: (entity_type) =>
            setSearch((old) => {
              old.search_by.entity_type = entity_type === null ? undefined : entity_type;
              return { ...old };
            }),
          fieldName: 'Entity Name',
        }),
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        filterDropdown: searchTextFilterTable({
          setSearchText: (destination) =>
            setSearch((old) => {
              old.search_by.destination = destination === null ? undefined : destination;
              return { ...old };
            }),
          fieldName: 'Destination',
        }),
      },
      {
        title: 'Body',
        dataIndex: 'body',
      },
      {
        title: 'Type',
        dataIndex: 'notification_type',
        filterDropdown: searchTextFilterTable({
          setSearchText: (notification_type) =>
            setSearch((old) => {
              old.search_by.notification_type = notification_type === null ? undefined : notification_type;
              return { ...old };
            }),
          fieldName: 'Event',
        }),
      },
      { title: 'ConfigID', dataIndex: 'config_id' },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        render: (created_at: string) => {
          return new Date(created_at).toDateString();
        },
        filterDropdown: searchRangeDateFilterTable({
          setRange: (value) => setSearch((old) => ({ ...old, date_range: value })),
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('created_at');
          },
        }),
      },
    ],
    [onChangeSort]
  );

  return (
    <>
      <div>{isLoading && <Skeleton />}</div>

      <div>
        {!isLoading && webhooksResp && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: webhooksResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </>
  );
};

export default History;
