import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';

import { vendorList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { Error3thix, SuccessVendorList, Vendor } from '@src/types';

import ModalCreateVendor from './components/ModalCreateVendor';
import ModalUpdateVendor from './components/ModalEditVendor';
import { COLUMNS, PAGE_SIZE } from './constants';

const Vendors = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [vendorsResp, setVendorsResp] = useState<SuccessVendorList | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);

  const listAll = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await vendorList(page, pageSize);
    if (status === 200 && data && 'vendors' in data && 'total' in data) {
      setVendorsResp(data as SuccessVendorList);
      setIsLoading(false);
    } else {
      message.error((data as Error3thix).message || 'Failed to fetch vendors');
      setIsLoading(false);
    }
  }, [page, pageSize]);

  const toggleCreateModal = useCallback(() => {
    setOpenCreateModal((prev) => !prev);
  }, []);
  const toggleUpdateModal = useCallback(() => {
    setOpenUpdateModal((prev) => !prev);
  }, []);

  const onRefresh = useCallback(() => {
    listAll();
  }, [listAll]);

  useEffect(() => {
    listAll();
  }, [listAll]);

  const rows = useMemo(() => {
    if (vendorsResp === null) {
      return [];
    }

    return vendorsResp.vendors;
  }, [vendorsResp]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: Vendor) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setSelectedVendor(record);
                  toggleUpdateModal();
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      ...COLUMNS,
    ];
  }, [setSelectedVendor, toggleUpdateModal]);

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-solid fa-building"></i> Vendors
          </Title>
        </div>
        <div className="flex">
          <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleCreateModal}>
            New Vendor
          </Button>
        </div>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && vendorsResp && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: vendorsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>

      <ModalCreateVendor isOpen={openCreateModal} toggleOpen={toggleCreateModal} onRefresh={onRefresh} />

      <ModalUpdateVendor
        isOpen={openUpdateModal}
        vendor={selectedVendor}
        toggleOpen={toggleUpdateModal}
        onRefresh={onRefresh}
      />
    </Container>
  );
};

export default Vendors;
