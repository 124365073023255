import { useCallback, useEffect, useState } from 'react';

import { Button, Checkbox, Input, message, Modal } from 'antd';

import { entityRoleUpdate } from '@src/clients/3thix';
import { permissionTypes } from '@src/constants';
import { EntityRole, Error3thix, UpdateEntityRoleRequest } from '@src/types';

type Props = {
  entityRole: EntityRole | null;
  isOpen: boolean;
  toggleOpen: () => void;
  onRefresh: () => void;
};

const ModalUpdateRole = ({ entityRole, isOpen, toggleOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<UpdateEntityRoleRequest>({
    id: '',
    name: '',
    description: '',
    permissions: [],
  });

  useEffect(() => {
    if (entityRole) {
      setFormData(entityRole);
    }
  }, [entityRole]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await entityRoleUpdate(formData);

      if (status === 200) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  const handlePermissionChange = useCallback((permissionType: string, isChecked: boolean) => {
    setFormData((old) => {
      const permissions = [...old.permissions];
      if (isChecked) {
        permissions.push(permissionType);
      } else {
        const index = permissions.findIndex((permission) => permission === permissionType);
        if (index !== -1) permissions.splice(index, 1);
      }
      return { ...old, permissions };
    });
  }, []);

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> Update Role
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label htmlFor="id">ID</label>
            <Input
              required
              disabled
              size="large"
              type="text"
              name="id"
              value={formData.id}
              placeholder="Fee ID"
              className="my-2 w-full"
            />
          </div>

          <div className="w-full">
            <label htmlFor="name">Name</label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              value={formData.name}
              placeholder="Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="description">Description</label>
            <Input
              size="large"
              type="text"
              name="description"
              value={formData.description}
              placeholder="Description"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="permissions">Permissions</label>
            {permissionTypes.map((permissionType) => {
              const role = formData.permissions.find((r) => r === permissionType);
              return (
                <div key={permissionType} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Checkbox checked={!!role} onChange={(e) => handlePermissionChange(permissionType, e.target.checked)}>
                    {permissionType}
                  </Checkbox>
                </div>
              );
            })}
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-edit" />}>
            Update Role
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalUpdateRole;
