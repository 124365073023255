import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, message, Skeleton, Table } from 'antd';

import { gamesListByPublisher } from '@src/clients/3thix';
import { Error3thix, SuccessGamesList } from '@src/types';

import ModalCreateGame from './components/ModalCreateGame';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  publisherID: string;
};

const GamesTable = ({ publisherID }: Props) => {
  const navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [gamesResp, setGamesResp] = useState<SuccessGamesList | null>(null);

  const getGames = useCallback(async () => {
    if (!publisherID) {
      return;
    }
    const { data, status } = await gamesListByPublisher(publisherID, page, pageSize);

    if (status === 200) {
      setGamesResp(data as SuccessGamesList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [publisherID, page, pageSize]);

  const toggleOpen = () => setOpenCreateModal((old) => !old);

  useEffect(() => {
    getGames();
  }, [getGames]);

  const rows = useMemo(() => {
    if (gamesResp === null) {
      return [];
    }

    return gamesResp.games;
  }, [gamesResp]);

  const onRow = useCallback(
    (record: { id: string }) => ({
      onClick: () => {
        navigate(`/publishers/${publisherID}/games/${record.id}`);
      },
    }),
    [publisherID, navigate]
  );

  if (!publisherID) {
    return null;
  }

  return (
    <div className="text-right">
      <Button
        type="default"
        size="large"
        icon={<i className="fas fa-plus" />}
        style={{ marginBottom: '20px' }}
        onClick={toggleOpen}
      >
        New Game
      </Button>

      <ModalCreateGame publisherId={publisherID} isOpen={openCreateModal} toggleOpen={toggleOpen} />

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && gamesResp !== null && (
          <Table
            dataSource={rows}
            columns={COLUMNS}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: gamesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
            onRow={onRow}
          />
        )}
      </div>
    </div>
  );
};

export default GamesTable;
