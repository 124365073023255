import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, message, Skeleton, Table } from 'antd';
import Title from 'antd/es/typography/Title';

import { transactionsLimitsList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { Error3thix, SuccessTransactionsLimitsList } from '@src/types';

import ModalCreate from './components/ModalCreate';
import { COLUMNS, PAGE_SIZE } from './constants';

const TransactionsLimits = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [transactionsLimits, setTransactionsLimits] = useState<SuccessTransactionsLimitsList>();

  const listAll = useCallback(async () => {
    const { data, status } = await transactionsLimitsList(page, pageSize);
    if (status === 200) {
      setTransactionsLimits(data as SuccessTransactionsLimitsList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize]);

  const toggleOpen = () => setOpenCreateModal((old) => !old);

  const onSuccess = useCallback(() => {
    setOpenCreateModal(false);
    listAll();
  }, [listAll]);

  useEffect(() => {
    listAll();
  }, [listAll]);

  const rows = useMemo(() => {
    return transactionsLimits ? transactionsLimits.transactions_limits : [];
  }, [transactionsLimits]);

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-solid fa-money-bill-transfer"></i> Transaction Limits
          </Title>
        </div>
        <div className="flex">
          <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleOpen}>
            New limit
          </Button>
        </div>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && transactionsLimits && (
          <Table
            dataSource={rows}
            rowKey={(index) => `row-${index}`}
            columns={COLUMNS}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: transactionsLimits ? transactionsLimits.total : 0,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>

      <ModalCreate isOpen={openCreateModal} toggleOpen={toggleOpen} onSuccess={onSuccess} />
    </Container>
  );
};

export default TransactionsLimits;
