import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';

import { fulfillmentList } from '@src/clients/3thix';
import { Error3thix, SuccessFulfillmentsList } from '@src/types';

import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  orderId: string;
};

const FulfillmentsTable = ({ orderId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [fulfillmentsResp, setFulfillmentsResp] = useState<SuccessFulfillmentsList>();

  const getFulfillments = useCallback(async () => {
    const { data, status } = await fulfillmentList(page, pageSize, orderId);

    if (status === 200) {
      setFulfillmentsResp(data as SuccessFulfillmentsList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, orderId]);

  useEffect(() => {
    getFulfillments();
  }, [getFulfillments]);

  const rows = useMemo(() => {
    if (!fulfillmentsResp) {
      return [];
    }

    return fulfillmentsResp.fulfillments;
  }, [fulfillmentsResp]);

  return (
    <div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && fulfillmentsResp && (
          <Table
            dataSource={rows}
            columns={COLUMNS}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: fulfillmentsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FulfillmentsTable;
