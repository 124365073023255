import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';

import { ordersListByGame } from '@src/clients/3thix';
import { Error3thix, SuccessOrdersList } from '@src/types';

import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  issuerEntityID?: string;
  fulfillmentIssuerEntityID?: string;
};

const OrdersTable = ({ issuerEntityID, fulfillmentIssuerEntityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [ordersResp, setOrdersResp] = useState<SuccessOrdersList | null>(null);

  const getOrders = useCallback(async () => {
    const { data, status } = await ordersListByGame(page, pageSize, issuerEntityID, fulfillmentIssuerEntityID);

    if (status === 200) {
      setOrdersResp(data as SuccessOrdersList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, issuerEntityID, fulfillmentIssuerEntityID]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const rows = useMemo(() => {
    if (ordersResp === null) {
      return [];
    }

    return ordersResp.orders;
  }, [ordersResp]);

  return (
    <div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && ordersResp !== null && (
          <Table
            dataSource={rows}
            columns={COLUMNS}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: ordersResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default OrdersTable;
