export const defaultValue = {
  amount: '',
  type: '',
  campaign_id: '',
  game_entity_id: '',
  max_redemption_per_campaign: 0,
  max_redemption_per_user: 0,
  source_account_id: null,
  currency: '',
  start_date: null,
  end_date: null,
};
