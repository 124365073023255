export const DEFAULT_SEARCH_VALUES = {
  account_id: undefined,
  network: undefined,
  rail: undefined,
  currency: undefined,
  transcode_id: undefined,
  transaction_id: undefined,
  is_settlement: undefined,
};

export const PAGE_SIZE = 10;
