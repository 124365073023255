import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Skeleton, Tabs } from 'antd';
import Title from 'antd/es/typography/Title';

import { userRead } from '@src/clients/3thix';
import AccountsTable from '@src/components/AccountsTable';
import AddressesTable from '@src/components/AddressesTable';
import Container from '@src/components/Container';
import OrdersTable from '@src/components/OrdersTable';
import UserRolesTable from '@src/components/UserRolesTable';
import { SuccessUserRead } from '@src/types';

const User = () => {
  const { id } = useParams();
  const [user, setUser] = useState<SuccessUserRead>();

  const getUser = async (id: string) => {
    const { data, status } = await userRead(id);
    if (status === 200) {
      setUser(data as SuccessUserRead);
      return;
    }
  };

  useEffect(() => {
    if (id) getUser(id);
  }, [id]);

  return (
    <Container
      breadcrumbs={[
        { name: 'Users', path: '/users' },
        { name: 'User', path: `/users/${id}` },
      ]}
    >
      <div className="mb-[20px]">
        {user ? <Title level={2}>{user && `${user.first_name} ${user.last_name}`}</Title> : <Skeleton.Input />}
      </div>

      <div className="mb-[20px]">{user ? <Title level={4}>{user.email}</Title> : <Skeleton.Input />}</div>

      <Tabs
        centered
        defaultActiveKey="1"
        items={[
          {
            label: 'Accounts',
            key: '1',
            children: <AccountsTable entityID={id as string} />,
          },
          {
            label: 'Orders',
            key: '2',
            children: <OrdersTable fulfillmentIssuerEntityID={id as string} />,
          },
          {
            label: 'User Roles',
            key: '3',
            children: <UserRolesTable parentEntityID={null} entityID={id as string} />,
          },
          {
            label: 'Addresses',
            key: '4',
            children: <AddressesTable entityID={id as string} />,
          },
        ]}
      />
    </Container>
  );
};

export default User;
