import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { contractList } from '@src/clients/3thix';
import { Contract, Error3thix, SuccessContractList } from '@src/types';

import ModalCreateContract from './components/ModalCreateContract';
import ModalEditContract from './components/ModalEditContract';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  parentEntityID: string | null;
  entityID: string;
};

const ContractsTable = ({ parentEntityID, entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [contractsResp, setContractsResp] = useState<SuccessContractList | null>(null);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

  const getContracts = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await contractList(entityID, page, pageSize);

    if (status === 200) {
      setContractsResp(data as SuccessContractList);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    message.error((data as Error3thix).message);
  }, [page, pageSize, entityID]);

  const toggleCreateModal = useCallback(() => {
    setOpenCreateModal((prev) => !prev);
  }, []);
  const toggleUpdateModal = useCallback(() => {
    setOpenUpdateModal((prev) => !prev);
  }, []);

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  const refreshContracts = useCallback(() => {
    getContracts();
  }, [getContracts]);

  const rows = useMemo(() => {
    if (contractsResp === null) {
      return [];
    }

    return contractsResp.contracts;
  }, [contractsResp]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: Contract) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setSelectedContract(record);
                  toggleUpdateModal();
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      ...COLUMNS,
    ];
  }, [setSelectedContract, toggleUpdateModal]);

  return (
    <div className="text-right">
      {entityID && (
        <Button
          type="default"
          size="large"
          icon={<i className="fas fa-plus" />}
          style={{ marginBottom: '20px' }}
          onClick={toggleCreateModal}
        >
          Create Contract
        </Button>
      )}

      <ModalCreateContract
        parentEntityID={parentEntityID}
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onSuccess={refreshContracts}
      />

      {selectedContract && (
        <ModalEditContract
          contract={selectedContract}
          isOpen={openUpdateModal}
          toggleOpen={toggleUpdateModal}
          onRefresh={getContracts}
        />
      )}

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && contractsResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: contractsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ContractsTable;
