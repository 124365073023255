import { createContext, useContext, useMemo, useState } from 'react';

import { Entity } from '@src/types';
import { setSelectedEntityId } from '@src/utils/EntityId';

interface Props {
  selectedEntity: Entity | null;
  setSelectedEntity: (entity: Entity | null) => void;
}

const EntityContext = createContext<Props | undefined>(undefined);

export const EntityProvider = ({ children }) => {
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);

  const handleSetSelectedEntity = (entity: Entity | null) => {
    setSelectedEntity(entity);
    setSelectedEntityId(entity ? entity.id : null);
  };

  const value = useMemo(() => ({ selectedEntity, setSelectedEntity: handleSetSelectedEntity }), [selectedEntity]);

  return <EntityContext.Provider value={value}>{children}</EntityContext.Provider>;
};

export const useEntity = (): Props => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error('useEntity must be used within a EntityProvider');
  }
  return context;
};
