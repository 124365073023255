import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { notificationEmailConfigsUpdate } from '@src/clients/3thix';
import { Error3thix } from '@src/types';

type Props = {
  updateModal?: { id: string; oldDestinationEmail: string };
  close: () => void;
  onSuccess: () => void;
};

const ModalUpdate = ({ close, updateModal, onSuccess }: Props) => {
  const [destinationEmail, setDestinationEmail] = useState<string>();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDestinationEmail(value);
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      if (!updateModal || !destinationEmail) return;

      event.preventDefault();

      const { status, data } = await notificationEmailConfigsUpdate(updateModal.id, destinationEmail);
      if (status === 204) {
        onSuccess();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [destinationEmail, updateModal, onSuccess]
  );

  useEffect(() => {
    if (updateModal) setDestinationEmail(updateModal.oldDestinationEmail);
  }, [updateModal]);

  return (
    <Modal
      open={!!updateModal}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-envelope mr-2"></i> E-mail Config
        </>
      }
      onOk={close}
      onCancel={close}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              E-mail
            </label>
            {destinationEmail && (
              <Input
                required
                size="large"
                type="text"
                name="destination_email"
                defaultValue={destinationEmail}
                placeholder="email@domain.com"
                className="my-2 w-full"
                onChange={handleChange}
              />
            )}
          </div>
          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Update
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalUpdate;
