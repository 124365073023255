import { ReactElement } from 'react';

import LoginWidget from '3thix-login-widget';

import useLogin from '@src/hooks/useLogin';

import SelfPromoteForm from './components/SelfPromoteForm';

type Props = {
  children: ReactElement[] | ReactElement;
};

const LoginWrapper = ({ children }: Props) => {
  const { isLoggedIn, isAdmin, setSessionToken } = useLogin();

  if (!isLoggedIn || isAdmin === undefined) {
    return <LoginWidget callback={setSessionToken} url={process.env.REACT_APP_3THIX_API || ''} />;
  }

  if (!isAdmin) {
    return <SelfPromoteForm />;
  }

  return <div>{children}</div>;
};

export default LoginWrapper;
