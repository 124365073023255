import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { vendorUpdate } from '@src/clients/3thix';
import { UpdateVendorRequest, Vendor } from '@src/types';

type Props = {
  vendor: Vendor | null;
  isOpen: boolean;
  toggleOpen: () => void;
  onRefresh: () => void;
};

const ModalEditVendor = ({ vendor, isOpen, toggleOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<UpdateVendorRequest>({
    id: '',
    name: '',
    address: '',
    email: '',
    phone: '',
    city: '',
    state_province: '',
    country_code: '',
    contact_name: '',
  });

  useEffect(() => {
    if (vendor) {
      setFormData({
        id: vendor.id,
        name: vendor.name,
        address: vendor.address || '',
        email: vendor.email || '',
        phone: vendor.phone || '',
        city: vendor.city || '',
        state_province: vendor.state_province || '',
        country_code: vendor.country_code || '',
        contact_name: vendor.contact_name || '',
      });
    }
  }, [vendor]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await vendorUpdate(formData);
      console.log('status', status);
      if (status === 200) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error(data?.message || 'Error updating vendor');
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal open={isOpen} footer={null} title="Edit Vendor Details" onOk={toggleOpen} onCancel={toggleOpen}>
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label htmlFor="name">Vendor Name</label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              value={formData.name}
              placeholder="Vendor Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="address">Address</label>
            <Input
              size="large"
              type="text"
              name="address"
              value={formData.address}
              placeholder="Address"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="email">Email</label>
            <Input
              size="large"
              type="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="phone">Phone</label>
            <Input
              size="large"
              type="text"
              name="phone"
              value={formData.phone}
              placeholder="Phone"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="city">City</label>
            <Input
              size="large"
              type="text"
              name="city"
              value={formData.city}
              placeholder="City"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="state_province">State/Province</label>
            <Input
              size="large"
              type="text"
              name="state_province"
              value={formData.state_province}
              placeholder="State/Province"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="country_code">Country Code</label>
            <Input
              size="large"
              type="text"
              name="country_code"
              value={formData.country_code}
              placeholder="Country Code"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="contact_name">Contact Name</label>
            <Input
              size="large"
              type="text"
              name="contact_name"
              value={formData.contact_name}
              placeholder="Contact Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-edit" />}>
            Update Vendor
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalEditVendor;
