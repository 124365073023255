import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal, Select, Switch } from 'antd';

import { contractCreate, vendorList } from '@src/clients/3thix';
import { CreateContractRequest, Error3thix, SuccessVendorList } from '@src/types';

type Props = {
  parentEntityID: string | null;
  entityID: string;
  toggleOpen: () => void;
  onSuccess: () => void;
  isOpen: boolean;
};

const ModalCreateContract = ({ parentEntityID, entityID, toggleOpen, isOpen, onSuccess }: Props) => {
  const [formData, setFormData] = useState<CreateContractRequest>({
    parent_entity_id: parentEntityID,
    entity_id: entityID,
    vendor_id: '',
    enabled: true,
    mid: '',
    mcc: '',
    caid: '',
    chargeback_fees: '',
    tokenization_fees: '',
  });
  const [vendorsResp, setVendorsResp] = useState<SuccessVendorList | null>(null);

  const getVendors = useCallback(async () => {
    const { data, status } = await vendorList(1, 100);
    if (status === 200) {
      setVendorsResp(data as SuccessVendorList);
    } else {
      message.error('Failed to fetch vendors.');
    }
  }, []);

  useEffect(() => {
    getVendors();
  }, [getVendors]);

  const handleChange = (name: string, value: string | boolean) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await contractCreate(formData);

      if (status === 201) {
        onSuccess();
        toggleOpen();
        return;
      }
      message.error((data as Error3thix).message);
    },
    [formData, toggleOpen, onSuccess]
  );

  const VendorSelect = (
    <Select<string>
      showSearch
      size="large"
      placeholder="Select a vendor"
      optionFilterProp="label"
      className="my-2 w-full"
      options={vendorsResp ? vendorsResp.vendors.map((vendor) => ({ label: vendor.name, value: vendor.id })) : []}
      onChange={(value) => handleChange('vendor_id', value)}
    />
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={700}
      title={
        <>
          <i className="fa-solid fa-file-contract"></i> Create Contract
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col">
            <label className="text-white font-[10px]">Vendor</label>
            {VendorSelect}

            <div className="flex flex-row w-full flex-wrap justify-between">
              <div className="flex flex-col w-[48%]">
                <label htmlFor="mid" className="text-white font-[10px] ml-[10px]">
                  MID
                </label>
                <Input
                  size="large"
                  name="mid"
                  placeholder="Enter MID"
                  className="my-2 w-full"
                  onChange={(e) => handleChange('mid', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="mcc" className="text-white font-[10px] ml-[10px]">
                  MCC
                </label>
                <Input
                  size="large"
                  name="mcc"
                  placeholder="Enter MCC"
                  className="my-2 w-full"
                  onChange={(e) => handleChange('mcc', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="caid" className="text-white font-[10px] ml-[10px]">
                  CAID
                </label>
                <Input
                  size="large"
                  name="caid"
                  placeholder="Enter CAID"
                  className="my-2 w-full"
                  onChange={(e) => handleChange('caid', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="chargeback_fees" className="text-white font-[10px] ml-[10px]">
                  Chargeback Fees
                </label>
                <Input
                  size="large"
                  name="chargeback_fees"
                  placeholder="Enter chargeback fees"
                  className="my-2 w-full"
                  onChange={(e) => handleChange('chargeback_fees', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="tokenization_fees" className="text-white font-[10px] ml-[10px]">
                  Tokenization Fees
                </label>
                <Input
                  size="large"
                  name="tokenization_fees"
                  placeholder="Enter tokenization fees"
                  className="my-2 w-full"
                  onChange={(e) => handleChange('tokenization_fees', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%] items-start">
                <label htmlFor="enabled" className="text-white font-[10px] ml-[10px]">
                  Enabled
                </label>
                <div className="flex items-center">
                  <Switch
                    checked={formData.enabled}
                    className="my-2 mr-2"
                    onChange={(checked) => handleChange('enabled', checked)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-check" />}>
            Create Contract
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateContract;
