import { useCallback, useState } from 'react';

import { Button, Input, message } from 'antd';

import { selfPromote } from '@src/clients/3thix';
import { Error3thix, SelfPromoteRequest } from '@src/types';

const SelfPromoteForm = () => {
  const [formData, setFormData] = useState<SelfPromoteRequest>({
    secretKey: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await selfPromote(formData.secretKey);
      if (status === 204) {
        window.location.reload();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData]
  );

  return (
    <div className="flex justify-center">
      <div className="flex flex-col my-10 p-[40px] w-full max-w-[700px]">
        <div className="flex pb-6 flex-row justify-between">
          <div className="flex">
            <img src="/logo.png" alt="logo" className="w-[118px] h-[26px]" />
          </div>
        </div>

        <div className="w-full rounded-[10px] p-10">
          <form onSubmit={handleSubmit}>
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Secret
              </label>
              <Input
                required
                size="large"
                type="text"
                name="secretKey"
                defaultValue={formData.secretKey}
                placeholder="Secret Key"
                className="my-2 w-full"
                onChange={handleChange}
              />
            </div>
            <Button size="large" className="my-2" htmlType="submit">
              Try Self Promote
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SelfPromoteForm;
