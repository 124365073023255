import React, { useEffect, useState } from 'react';

import { Descriptions, Modal, Spin } from 'antd';

import { balanceCurrent } from '@src/clients/3thix';
import { Balance } from '@src/types';

type Props = {
  accountCurrencyId: string;
  isOpen: boolean;
  toggleOpen: () => void;
};

const ModalViewBalance: React.FC<Props> = ({ accountCurrencyId, isOpen, toggleOpen }) => {
  const [balance, setBalance] = useState<Balance | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      if (!accountCurrencyId) return;
      setIsLoading(true);
      const { status, data } = await balanceCurrent(accountCurrencyId);
      if (status === 200 && isOpen) {
        setBalance(data as Balance);
      } else {
        setBalance(null);
      }
      setIsLoading(false);
    };

    if (isOpen) {
      fetchBalance();
    }
  }, [accountCurrencyId, isOpen]);

  return (
    <Modal title="Account Balance" open={isOpen} footer={null} onCancel={toggleOpen}>
      {isLoading ? (
        <Spin />
      ) : balance ? (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Effective">{balance.effective}</Descriptions.Item>
          <Descriptions.Item label="Available">{balance.available}</Descriptions.Item>
          <Descriptions.Item label="Updated At">{new Date(balance.created_at).toDateString()}</Descriptions.Item>
        </Descriptions>
      ) : (
        <p>No balance data available.</p>
      )}
    </Modal>
  );
};

export default ModalViewBalance;
