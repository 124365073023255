export const COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Name', dataIndex: 'name' },
  { title: 'Address 1', dataIndex: 'address1' },
  { title: 'Address 2', dataIndex: 'address2' },
  { title: 'City', dataIndex: 'city' },
  { title: 'State/Province', dataIndex: 'state_province' },
  { title: 'Postal Code', dataIndex: 'postal_code' },
  { title: 'Country Code', dataIndex: 'country_code' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return created_at ? new Date(created_at).toDateString() : 'N/A';
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return updated_at ? new Date(updated_at).toDateString() : 'N/A';
    },
  },
];

export const PAGE_SIZE = 10;
