export const COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Rail', dataIndex: 'rail' },
  { title: 'Currency', dataIndex: 'currency' },
  { title: 'Buyer to Seller Amount Percentage', dataIndex: 'buyer_to_seller_amount_percentage' },
  { title: 'Buyer to Seller Amount Fixed', dataIndex: 'buyer_to_seller_amount_fixed' },
  { title: 'Buyer to House Amount Percentage', dataIndex: 'buyer_to_house_amount_percentage' },
  { title: 'Buyer to House Amount Fixed', dataIndex: 'buyer_to_house_amount_fixed' },
  { title: 'Seller to House Amount Percentage', dataIndex: 'seller_to_house_amount_percentage' },
  { title: 'Seller to House Amount Fixed', dataIndex: 'seller_to_house_amount_fixed' },
  { title: 'Pay Network', dataIndex: 'pay_network' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];

export const PAGE_SIZE = 5;
