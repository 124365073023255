import { useParams } from 'react-router-dom';

import { Tabs } from 'antd';
import Title from 'antd/es/typography/Title';

import AccountsTable from '@src/components/AccountsTable';
import Container from '@src/components/Container';
import FeesTable from '@src/components/FeesTable';
import RolesTable from '@src/components/RolesTable';
import UserRolesTable from '@src/components/UserRolesTable';

const Root = () => {
  const { id } = useParams();

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-solid fa-building"></i> 3thix
          </Title>
        </div>
      </div>
      <Tabs
        centered
        defaultActiveKey="1"
        items={[
          {
            label: 'Accounts',
            key: '1',
            children: <AccountsTable entityID={id as string} />,
          },
          {
            label: 'Fee Policies',
            key: '2',
            children: <FeesTable entityID={id as string} />,
          },
          {
            label: 'Roles',
            key: '3',
            children: <RolesTable entityID={id as string} />,
          },
          {
            label: 'Users Roles',
            key: '4',
            children: <UserRolesTable parentEntityID={id as string} entityID={null} />,
          },
        ]}
      />
    </Container>
  );
};

export default Root;
