export const COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Name', dataIndex: 'name' },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'State/Province',
    dataIndex: 'state_province',
  },
  {
    title: 'Country Code',
    dataIndex: 'country_code',
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact_name',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: (created_at: string) => new Date(created_at).toDateString(),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: (updated_at: string) => (updated_at ? new Date(updated_at).toDateString() : 'N/A'),
  },
];

export const PAGE_SIZE = 5;
