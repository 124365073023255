import { Button, DatePicker, Input, InputNumber, Space } from 'antd';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/es/table/interface';

type PropsText = {
  fieldName: string;
  setSearchText: (value: string | null) => void;
};

import { SearchOutlined } from '@ant-design/icons';

type SearchComponentResult = ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => JSX.Element;

export const searchTextFilterTable = ({ setSearchText, fieldName }: PropsText): SearchComponentResult => {
  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    setSearchText(null);
    setSelectedKeys([]);
    confirm();
  };

  return ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps): JSX.Element => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        placeholder={`Search ${fieldName}`}
        value={selectedKeys[0]}
        style={{ marginBottom: 8, display: 'block' }}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          onClick={() => handleSearch(selectedKeys as string[], confirm)}
        >
          Search
        </Button>
        <Button size="small" style={{ width: 90 }} onClick={() => handleReset(setSelectedKeys, confirm)}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

type rangeKey = string | number;

type PropsRange = {
  fieldName: string;
  defaultValue: { start: rangeKey; end: rangeKey };
  setRange: (value: { start: rangeKey; end: rangeKey } | null) => void;
};

export const searchRangeMoneyFilterTable = ({
  setRange,
  fieldName,
  defaultValue,
}: PropsRange): SearchComponentResult => {
  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setRange({ start: selectedKeys[0], end: selectedKeys[1] });
  };

  const handleReset = (
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    setRange(null);
    setSelectedKeys([]);
    confirm();
  };

  return ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps): JSX.Element => {
    if (selectedKeys.length < 2) {
      selectedKeys = [defaultValue.start, defaultValue.end];
    }
    return (
      <div style={{ padding: 8, width: '200px' }} onKeyDown={(e) => e.stopPropagation()}>
        <InputNumber
          addonBefore="$"
          defaultValue={defaultValue.start}
          placeholder={`Start ${fieldName}`}
          style={{ marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          onChange={(e) => setSelectedKeys(e ? [e, selectedKeys[1]] : [defaultValue.start, selectedKeys[1]])}
        />
        <InputNumber
          addonBefore="$"
          defaultValue={defaultValue.end}
          placeholder={`End ${fieldName}`}
          style={{ marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          onChange={(e) => setSelectedKeys(e ? [selectedKeys[0], e] : [selectedKeys[0], defaultValue.end])}
        />
        <Space>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
          >
            Search
          </Button>
          <Button size="small" style={{ width: 90 }} onClick={() => handleReset(setSelectedKeys, confirm)}>
            Reset
          </Button>
        </Space>
      </div>
    );
  };
};

type PropsDateRange = {
  setRange: (value: { start: string; end: string } | null) => void;
};

const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;

export const searchRangeDateFilterTable = ({ setRange }: PropsDateRange): SearchComponentResult => {
  return ({ confirm }: FilterDropdownProps): JSX.Element => {
    return (
      <div style={{ padding: 8, width: '250px' }} onKeyDown={(e) => e.stopPropagation()}>
        <div style={{ marginBottom: 10 }}>
          <RangePicker
            format={dateFormat}
            onChange={(values) => {
              if (values === null || values[0] === null || values[1] === null) {
                return setRange(null);
              }

              setRange({
                start: values[0].format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: values[1].format('YYYY-MM-DDTHH:mm:ss[Z]'),
              });
            }}
          />
        </div>
        <Space>
          <Button type="primary" icon={<SearchOutlined />} size="small" style={{ width: 90 }} onClick={() => confirm()}>
            Search
          </Button>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={() => {
              setRange(null);
              confirm();
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  };
};
