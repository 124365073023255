import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Input, message, Modal, Select } from 'antd';

import { grantUserRole, roleList } from '@src/clients/3thix';
import { Error3thix, SuccessEntityRoleList } from '@src/types';

type Props = {
  parentEntityID: string;
  entityID: string | null;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalGrantUserRole = ({ parentEntityID, entityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [rolesResp, setRolesResp] = useState<SuccessEntityRoleList | null>(null);
  const [formData, setFormData] = useState({
    parent_entity_id: parentEntityID,
    entity_id: entityID,
    role_id: '',
    email: '',
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      parent_entity_id: parentEntityID,
      entity_id: entityID,
    }));
  }, [parentEntityID, entityID]);

  const getRoles = useCallback(async () => {
    if (!parentEntityID) {
      return;
    }
    const { data, status } = await roleList(parentEntityID, 1, 1000);

    if (status === 200) {
      setRolesResp(data as SuccessEntityRoleList);
      return;
    }
    message.error((data as Error3thix).message);
  }, [parentEntityID]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const rows = useMemo(() => {
    if (rolesResp === null) {
      return [];
    }

    return rolesResp.roles;
  }, [rolesResp]);

  const handleChangeSelect = useCallback((value: string, fieldName: string) => {
    setFormData((old) => ({ ...old, [fieldName]: value }));
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { status, data } = await grantUserRole(formData);

    if (status === 201) {
      onRefresh();
      toggleOpen();
      return;
    }

    const errorMessage = (data as Error3thix).details
      ? `${(data as Error3thix).message}: ${(data as Error3thix).details}`
      : (data as Error3thix).message;
    message.error(errorMessage);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> Grant User Role
        </>
      }
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          {entityID === null && (
            <div className="w-full">
              <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
                Email
              </label>
              <Input
                required
                type="email"
                name="email"
                value={formData.email}
                className="w-full my-2 p-2 rounded-lg bg-[#1E2026] border border-[#1E2026] text-white"
                onChange={(e) => setFormData((old) => ({ ...old, email: e.target.value }))}
              />
            </div>
          )}
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="role">
              Role
            </label>
            <Select<string>
              size="large"
              value={formData.role_id}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'role_id')}
            >
              {rows && rows.length > 0 ? (
                rows.map((role) => (
                  <Select.Option key={role.id} value={role.id}>
                    {role.name}
                  </Select.Option>
                ))
              ) : (
                <Select.Option disabled value="">
                  No roles available
                </Select.Option>
              )}
            </Select>
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Grant User Role
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalGrantUserRole;
