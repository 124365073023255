import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';

import { campaignEnableToggle, campaignList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { Campaign, Error3thix, SuccessCampaignList } from '@src/types';

import ModalCreate from './components/ModalCreate';

const PAGE_SIZE = 20;

const Campaigns = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [campaigns, setCampaigns] = useState<SuccessCampaignList>();

  const listAll = useCallback(async () => {
    const { data, status } = await campaignList('', page, pageSize);
    if (status === 200) {
      setCampaigns(data as SuccessCampaignList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize]);

  const toggleOpen = () => setOpenCreateModal((old) => !old);

  const onSuccess = useCallback(() => {
    setOpenCreateModal(false);
    listAll();
  }, [listAll]);

  useEffect(() => {
    listAll();
  }, [listAll]);

  const toggleEnable = useCallback(
    async (id, enabled) => {
      await campaignEnableToggle(id, enabled ? 'disable' : 'enable');
      listAll();
    },
    [listAll]
  );

  const rows = useMemo(() => {
    return campaigns ? campaigns.campaigns : [];
  }, [campaigns]);

  const COLUMNS = useMemo(
    () => [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: Campaign) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Enable/Disable">
              <Button
                type="default"
                size="middle"
                icon={<i className="fa-solid fa-power-off"></i>}
                onClick={() => toggleEnable(record.id, record.enabled)}
              />
            </Tooltip>
          </Flex>
        ),
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        render: (enabled: boolean) => {
          return enabled ? 'Yes' : 'No';
        },
      },
      { title: 'ID', dataIndex: 'id' },
      { title: 'Name', dataIndex: 'name' },
      { title: 'External Campaign ID', dataIndex: 'external_campaign_id' },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        render: (created_at: string) => {
          return new Date(created_at).toDateString();
        },
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        render: (updated_at: string) => {
          return new Date(updated_at).toDateString();
        },
      },
    ],
    [toggleEnable]
  );

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-solid fa-money-bill-transfer"></i> Campaings
          </Title>
        </div>
        <div className="flex">
          <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleOpen}>
            New Campaign
          </Button>
        </div>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && campaigns && (
          <Table
            dataSource={rows}
            rowKey={(index) => `row-${index}`}
            columns={COLUMNS}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: campaigns ? campaigns.total : 0,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>

      <ModalCreate isOpen={openCreateModal} toggleOpen={toggleOpen} onSuccess={onSuccess} />
    </Container>
  );
};

export default Campaigns;
