import { useCallback, useEffect, useState } from 'react';

import { Modal, Select, Spin } from 'antd';

import { userMe } from '@src/clients/3thix';
import { useEntity } from '@src/contexts/EntityContext';
import { Entity, SuccessUserRead } from '@src/types';

const EntitySelector = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState<Entity[]>([]);
  const { selectedEntity, setSelectedEntity } = useEntity();

  const handleFetchEntities = useCallback(async () => {
    if (!selectedEntity) {
      const response = await userMe();
      if (response.status === 200) {
        const data = response.data as SuccessUserRead;
        setEntities(data.entities);
        if (data.entities.length === 1) {
          setSelectedEntity(data.entities[0]);
        }
      } else {
        console.error('Failed to fetch entities:', response.data);
      }
      setLoading(false);
    }
  }, [selectedEntity, setSelectedEntity]);

  useEffect(() => {
    handleFetchEntities();
  }, [handleFetchEntities]);

  const handleEntityChange = (entityId: string) => {
    const entity = entities.find((t) => t.id === entityId);
    if (entity) {
      setSelectedEntity(entity);
    } else {
      console.error(`Entity with id ${entityId} not found`);
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!selectedEntity) {
    return (
      <Modal open closable={false} title="Select the Entity" footer={null}>
        <Select style={{ width: '100%' }} placeholder="Please select an entity" onChange={handleEntityChange}>
          {entities.map((entity) => (
            <Select.Option key={entity.id} value={entity.id}>
              {entity.business_name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    );
  }

  return children;
};

export default EntitySelector;
