export const COLUMNS = [
  { title: 'id', dataIndex: 'id' },
  { title: 'Issuer Entity ID', dataIndex: 'issuer_entity_id' },
  { title: 'Fulfillment Game User ID', dataIndex: 'fulfillment_game_user_id' },
  { title: 'Fulfillment Entity ID', dataIndex: 'fulfillment_entity_id' },
  { title: 'Type', dataIndex: 'type' },
  { title: 'Destination Rail', dataIndex: 'destination_rail' },
  { title: 'Destination Currency', dataIndex: 'destination_currency' },
  { title: 'Destination Amount', dataIndex: 'destination_amount' },
  { title: 'Destination Fees', dataIndex: 'destination_fees' },
  { title: 'Status', dataIndex: 'status' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];
export const PAGE_SIZE = 10;
