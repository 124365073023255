import { Tabs } from 'antd';
import Title from 'antd/es/typography/Title';

import Container from '@src/components/Container';

import Emails from './components/Emails';
import History from './components/History';
import Webhooks from './components/Webhooks';

const Notifications = () => {
  return (
    <Container>
      <div className="mb-[10px]">
        <Title level={2}>
          <i className="fa-solid fa-circle-exclamation"></i> Notifications
        </Title>
      </div>

      <Tabs
        centered
        defaultActiveKey="1"
        items={[
          {
            label: 'History',
            key: '1',
            children: <History />,
          },
          {
            label: 'Webhooks Configs',
            key: '2',
            children: <Webhooks />,
          },
          {
            label: 'E-mails Configs',
            key: '3',
            children: <Emails />,
          },
        ]}
      />
    </Container>
  );
};

export default Notifications;
