export const COLUMNS = [
  { title: 'id', dataIndex: 'id' },
  { title: 'Amount', dataIndex: 'amount' },
  { title: 'Currency', dataIndex: 'currency' },
  { title: 'Status', dataIndex: 'status' },
  { title: 'Source Account', dataIndex: 'source_account_id' },
  { title: 'Destination Account', dataIndex: 'destination_account_id' },
  { title: 'Error Message', dataIndex: 'error_msg' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];

export const PAGE_SIZE = 10;
