import { useCallback } from 'react';

import { App, Button, Input, message } from 'antd';

import { apiKeysCreate } from '@src/clients/3thix';
import { Error3thix, SuccessApiKeyCreate } from '@src/types';

type Props = {
  gameId: string;
  onSuccess: () => void;
};

const ModalCreate = ({ gameId, onSuccess }: Props) => {
  const { modal } = App.useApp();

  const handleCreate = useCallback(async () => {
    const { status, data } = await apiKeysCreate(gameId);
    if (status === 201) {
      modal.success({
        title: 'Here is your new API Key',
        content: <Input value={(data as SuccessApiKeyCreate).api_key} />,
        onOk: onSuccess,
        footer: (_, { OkBtn }) => <OkBtn />,
      });
      return;
    }

    message.error((data as Error3thix).message);
  }, [gameId, modal, onSuccess]);

  const confirmModal = useCallback(() => {
    modal.confirm({
      title: 'Create new API Key',
      content: 'Do you wanna create a new API Key?',
      onOk: handleCreate,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }, [handleCreate, modal]);

  return (
    <div>
      <Button
        type="default"
        size="large"
        icon={<i className="fas fa-plus" />}
        style={{ marginBottom: '20px' }}
        onClick={confirmModal}
      >
        New API Key
      </Button>
    </div>
  );
};

export default ModalCreate;
