import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { revokeUserRole } from '@src/clients/3thix';
import { Error3thix, RevokeRoleRequest, UserRole } from '@src/types';

type Props = {
  userRole: UserRole;
  isOpen: boolean;
  toggleOpen: () => void;
  onRefresh: () => void;
};

const ModalRevokeUserRole = ({ userRole, isOpen, toggleOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<RevokeRoleRequest>({
    parent_entity_id: userRole?.parent_entity_id,
    entity_id: userRole?.entity_id,
    role_id: userRole?.role_id,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      parent_entity_id: userRole?.parent_entity_id,
      entity_id: userRole?.entity_id,
      role_id: userRole?.role_id,
    }));
  }, [userRole]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await revokeUserRole(formData);

      if (status === 200) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> Revoke User Role
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label htmlFor="name">Name</label>
            <Input
              required
              disabled
              size="large"
              type="text"
              name="name"
              value={userRole?.role_name || ''}
              placeholder="Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-edit" />}>
            Revoke User Role
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalRevokeUserRole;
