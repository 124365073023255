export const DEFAULT_SEARCH_VALUES = {
  entity_id: undefined,
  entity_type: undefined,
  entity_name: undefined,
  notification_event: undefined,
  notification_type: undefined,
  destination: undefined,
};

export const PAGE_SIZE = 10;
