import { useCallback, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { userCreate } from '@src/clients/3thix';
import { Error3thix } from '@src/types';

type Props = {
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalCreateUser = ({ entityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState({
    entity_id: entityID,
    email: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((old) => ({ ...old, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await userCreate(formData);

      if (status === 201) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> New User
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label htmlFor="name">Email</label>
            <Input
              required
              size="large"
              type="text"
              name="email"
              value={formData.email}
              placeholder="Account Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create User
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateUser;
