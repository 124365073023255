import { useCallback, useState } from 'react';

import { Button, Checkbox, Input, message, Modal } from 'antd';

import { entityRoleCreate } from '@src/clients/3thix';
import { permissionTypes } from '@src/constants';
import { Error3thix } from '@src/types';

type Props = {
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalCreateRole = ({ entityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState({
    entity_id: entityID,
    name: '',
    description: '',
    permissions: [] as string[],
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((old) => ({ ...old, [name]: value }));
  }, []);

  const handlePermissionChange = useCallback((permissionType: string, isChecked: boolean) => {
    setFormData((old) => {
      const permissions = [...old.permissions];
      if (isChecked) {
        permissions.push(permissionType);
      } else {
        const index = permissions.findIndex((permission) => permission === permissionType);
        if (index !== -1) permissions.splice(index, 1);
      }
      return { ...old, permissions };
    });
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { status, data } = await entityRoleCreate(formData);

    if (status === 201) {
      onRefresh();
      toggleOpen();
      return;
    }

    const errorMessage = (data as Error3thix).details
      ? `${(data as Error3thix).message}: ${(data as Error3thix).details}`
      : (data as Error3thix).message;
    message.error(errorMessage);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> New Role
        </>
      }
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-gray-500">Name</label>
            <Input required name="name" type="text" value={formData.name} className="w-full" onChange={handleChange} />
          </div>

          <div className="w-full">
            <label className="text-gray-500">Description</label>
            <Input
              name="description"
              type="text"
              value={formData.description}
              className="w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="permissions">Permissions</label>
            {permissionTypes.map((permissionType) => {
              const role = formData.permissions.find((r) => r === permissionType);
              return (
                <div key={permissionType} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Checkbox checked={!!role} onChange={(e) => handlePermissionChange(permissionType, e.target.checked)}>
                    {permissionType}
                  </Checkbox>
                </div>
              );
            })}
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create Role
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateRole;
