import { useCallback, useState } from 'react';

import { Button, Input, message, Modal, Switch, Typography } from 'antd';

import { contractUpdate } from '@src/clients/3thix';
import { Contract, Error3thix, UpdateContractRequest } from '@src/types';

type Props = {
  contract: Contract;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const { Text } = Typography;

const ModalEditContract = ({ contract, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<UpdateContractRequest>({
    id: contract.id,
    mid: contract.mid ?? '',
    mcc: contract.mcc ?? '',
    caid: contract.caid ?? '',
    chargeback_fees: contract.chargeback_fees ?? '',
    tokenization_fees: contract.tokenization_fees ?? '',
    enabled: contract.enabled,
  });

  const handleChange = (name: string, value: string | boolean) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await contractUpdate(formData);

      if (status === 200) {
        onRefresh();
        toggleOpen();
      } else {
        const errorMessage = (data as Error3thix).details
          ? `${(data as Error3thix).message}: ${(data as Error3thix).details}`
          : (data as Error3thix).message;
        message.error(errorMessage);
      }
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal open={isOpen} footer={null} width={700} title="Edit Contract" onCancel={toggleOpen}>
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col">
            <div className="flex flex-row w-full flex-wrap justify-between">
              <div className="flex flex-col w-[48%]">
                <label htmlFor="mid" className="text-white font-[10px] ml-[10px]">
                  MID
                </label>
                <Input
                  size="large"
                  name="mid"
                  placeholder="Enter MID"
                  className="my-2 w-full"
                  value={formData.mid ?? ''}
                  onChange={(e) => handleChange('mid', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="mcc" className="text-white font-[10px] ml-[10px]">
                  MCC
                </label>
                <Input
                  size="large"
                  name="mcc"
                  placeholder="Enter MCC"
                  className="my-2 w-full"
                  value={formData.mcc ?? ''}
                  onChange={(e) => handleChange('mcc', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="caid" className="text-white font-[10px] ml-[10px]">
                  CAID
                </label>
                <Input
                  size="large"
                  name="caid"
                  placeholder="Enter CAID"
                  className="my-2 w-full"
                  value={formData.caid ?? ''}
                  onChange={(e) => handleChange('caid', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="chargeback_fees" className="text-white font-[10px] ml-[10px]">
                  Chargeback Fees
                </label>
                <Input
                  size="large"
                  name="chargeback_fees"
                  placeholder="Enter chargeback fees"
                  className="my-2 w-full"
                  value={formData.chargeback_fees ?? ''}
                  onChange={(e) => handleChange('chargeback_fees', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%]">
                <label htmlFor="tokenization_fees" className="text-white font-[10px] ml-[10px]">
                  Tokenization Fees
                </label>
                <Input
                  size="large"
                  name="tokenization_fees"
                  placeholder="Enter tokenization fees"
                  className="my-2 w-full"
                  value={formData.tokenization_fees ?? ''}
                  onChange={(e) => handleChange('tokenization_fees', e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[48%] items-start">
                <label htmlFor="enabled" className="text-white font-[10px] ml-[10px]">
                  Enabled
                </label>
                <div className="flex items-center">
                  <Switch
                    checked={formData.enabled}
                    className="my-2 mr-2"
                    onChange={(checked) => handleChange('enabled', checked)}
                  />
                  <Text>{formData.enabled ? 'Enabled' : 'Disabled'}</Text>
                </div>
              </div>
            </div>
          </div>

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-save" />}>
            Update Contract
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalEditContract;
