import { useCallback, useEffect, useMemo, useState } from 'react';

import { App, Button, message, Skeleton, Table, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';

import { transactionsLimitsGet, transactionsLimitsUnset } from '@src/clients/3thix';
import { Error3thix, SuccessLimitsGet, TransactionLimits } from '@src/types';

import ModalCreate from './components/ModalCreate';
import { COLUMNS, COLUMNS_WITH_DELETE } from './constants';

type Props = {
  id: string;
};

const TransactionsLimits = ({ id }: Props) => {
  const { modal } = App.useApp();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allLimits, setAllLimits] = useState<TransactionLimits[]>();
  const [mergedLimits, setMergedLimits] = useState<TransactionLimits | null>(null);

  const listAll = useCallback(async () => {
    const { data, status } = await transactionsLimitsGet(id);
    if (status === 200) {
      const gameTransactionLimit = data as SuccessLimitsGet;
      setMergedLimits(gameTransactionLimit.limits_merged);
      setAllLimits(gameTransactionLimit.limits);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [id]);

  const onSuccess = useCallback(() => {
    setOpenCreateModal(false);
    listAll();
  }, [listAll]);

  const toggleOpen = () => setOpenCreateModal((old) => !old);

  useEffect(() => {
    listAll();
  }, [listAll]);

  const rowsAllLimits = useMemo(() => {
    return !allLimits
      ? []
      : allLimits.map((value) => {
          return {
            status: (
              <Tooltip title="Unset limit">
                <Button
                  type="default"
                  size="middle"
                  icon={<i className="fa-solid fa-trash-can"></i>}
                  onClick={() =>
                    modal.confirm({
                      title: 'Unset transaction limit',
                      content: 'Are you sure you want to unset this transaction limit?',
                      onOk: async () => {
                        const { status } = await transactionsLimitsUnset(id, value.id);
                        if (status === 200) {
                          listAll();
                          return;
                        }
                      },
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                    })
                  }
                />
              </Tooltip>
            ),
            ...value,
          };
        });
  }, [allLimits, id, listAll, modal]);

  return (
    <div>
      <div className="mb-[20px] flex justify-between">
        <div className="w-full text-right">
          <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleOpen}>
            Set limit
          </Button>
        </div>
      </div>
      <div>
        {isLoading && <Skeleton />}
        {!isLoading && mergedLimits && (
          <>
            <Title level={4}>Merged limits</Title>
            <Table
              rowKey={(index) => `merged-${index}`}
              dataSource={[mergedLimits]}
              columns={COLUMNS}
              pagination={false}
            />
          </>
        )}

        {isLoading && <Skeleton />}
        {!isLoading && rowsAllLimits && (
          <>
            <Title level={4} className="mt-10">
              All set limits
            </Title>
            <Table
              rowKey={(index) => `limits-${index}`}
              dataSource={rowsAllLimits}
              columns={COLUMNS_WITH_DELETE}
              pagination={{
                position: ['bottomCenter'],
              }}
            />
          </>
        )}
      </div>

      <ModalCreate isOpen={openCreateModal} toggleOpen={toggleOpen} id={id} onSuccess={onSuccess} />
    </div>
  );
};

export default TransactionsLimits;
