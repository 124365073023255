import { useCallback, useEffect, useState } from 'react';

import { userMe } from '@src/clients/3thix';
import { SuccessUserRead } from '@src/types';

import { getToken, setToken } from './utils';

const useLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean>();

  const setSessionToken = async (token: string) => {
    setToken(token);
    await handleCheckIsAdmin();
    window.location.reload();
  };

  const handleCheckIsAdmin = useCallback(async () => {
    const { data, status } = await userMe();
    if (status !== 200) {
      return;
    }

    const successMe = data as SuccessUserRead;
    setIsAdmin(successMe?.entities && successMe.entities.length > 0);
  }, []);

  useEffect(() => {
    const tokenFromCookie = getToken();
    if (tokenFromCookie !== null) {
      setIsLoggedIn(true);
      handleCheckIsAdmin();
      return;
    }
  }, [handleCheckIsAdmin]);

  return {
    isLoggedIn,
    isAdmin,
    setSessionToken,
  };
};

export default useLogin;
