let selectedEntityId: string | null = null;

export const setSelectedEntityId = (entityId: string | null) => {
  selectedEntityId = entityId;
};

export const getSelectedEntityId = (): string | null => {
  return selectedEntityId;
};

export const clearSelectedEntityId = () => {
  selectedEntityId = null;
};
