import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tag, Tooltip } from 'antd';

import { roleList } from '@src/clients/3thix';
import { EntityRole, Error3thix, SuccessEntityRoleList } from '@src/types';

import ModalCreateRole from './components/ModalCreateRole';
import ModalUpdateRole from './components/ModalUpdateRole';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  entityID: string;
};

const RolesTable = ({ entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [rolesResp, setRolesResp] = useState<SuccessEntityRoleList | null>(null);
  const [selectedRole, setSelectedRole] = useState<EntityRole | null>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const getRoles = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await roleList(entityID, page, pageSize);

    if (status === 200) {
      setRolesResp(data as SuccessEntityRoleList);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    message.error((data as Error3thix).message);
  }, [page, pageSize, entityID]);

  const toggleCreateModal = () => setOpenCreateModal((prev) => !prev);
  const toggleUpdateModal = useCallback(() => {
    setOpenUpdateModal((prev) => !prev);
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const refreshRoles = useCallback(() => {
    getRoles();
  }, [getRoles]);

  const rows = useMemo(() => {
    if (rolesResp === null) {
      return [];
    }

    return rolesResp.roles;
  }, [rolesResp]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: EntityRole) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setSelectedRole(record);
                  toggleUpdateModal();
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      {
        title: 'Permissions',
        dataIndex: 'permissions',
        key: 'permissions',
        render: (permissions: string[]) => {
          return permissions.map((permission) => (
            <Tag key={permission} color="green">
              {permission}
            </Tag>
          ));
        },
      },
      ...COLUMNS,
    ];
  }, [setSelectedRole, toggleUpdateModal]);

  return (
    <div className="text-right">
      <Button
        type="default"
        size="large"
        icon={<i className="fas fa-plus" />}
        style={{ marginBottom: '20px' }}
        onClick={toggleCreateModal}
      >
        New Role
      </Button>
      <ModalCreateRole
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onRefresh={refreshRoles}
      />

      <ModalUpdateRole
        entityRole={selectedRole}
        isOpen={openUpdateModal}
        toggleOpen={toggleUpdateModal}
        onRefresh={getRoles}
      />

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && rolesResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: rolesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RolesTable;
