export const COLUMNS = [
  { title: 'id', dataIndex: 'id' },
  { title: 'Currency', dataIndex: 'currency' },
  { title: 'Amount', dataIndex: 'amount' },
  { title: 'Fees', dataIndex: 'fees' },
  { title: 'Status', dataIndex: 'status' },
  { title: 'Error Message', dataIndex: 'error_msg' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];

export const PAYMENT_COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Amount', dataIndex: 'destination_amount' },
  { title: 'Fees', dataIndex: 'destination_fees' },
  { title: 'Total', dataIndex: 'destination_total' },
  { title: 'Currency', dataIndex: 'destination_currency' },
  { title: 'Source Account', dataIndex: 'source_account_id' },
  { title: 'Destination Account', dataIndex: 'destination_account_id' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
];

export const PAGE_SIZE = 10;
