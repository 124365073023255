import { useCallback, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { vendorCreate } from '@src/clients/3thix';
import { CreateVendorRequest, Error3thix } from '@src/types';

type Props = {
  toggleOpen: () => void;
  onRefresh: () => void;
  isOpen: boolean;
};

const defaultValue: CreateVendorRequest = {
  name: '',
  address: '',
  email: '',
  phone: '',
  city: '',
  state_province: '',
  country_code: '',
  contact_name: '',
};

const ModalCreateVendor = ({ toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<CreateVendorRequest>(defaultValue);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await vendorCreate(formData);
      if (status === 201) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-building"></i> New Vendor
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="name">
              Vendor Name
            </label>
            <Input
              size="large"
              type="text"
              name="name"
              value={formData.name}
              placeholder="Enter vendor name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="address">
              Address
            </label>
            <Input
              size="large"
              type="text"
              name="address"
              value={formData.address}
              placeholder="Enter address"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="email">
              Email
            </label>
            <Input
              size="large"
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter email"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="phone">
              Phone
            </label>
            <Input
              size="large"
              type="text"
              name="phone"
              value={formData.phone}
              placeholder="Enter phone number"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="city">
              City
            </label>
            <Input
              size="large"
              type="text"
              name="city"
              value={formData.city}
              placeholder="Enter city"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="state_province">
              State/Province
            </label>
            <Input
              size="large"
              type="text"
              name="state_province"
              value={formData.state_province}
              placeholder="Enter state or province"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country_code">
              Country Code
            </label>
            <Input
              size="large"
              type="text"
              name="country_code"
              value={formData.country_code}
              placeholder="Enter country code"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="contact_name">
              Contact Name
            </label>
            <Input
              size="large"
              type="text"
              name="contact_name"
              value={formData.contact_name}
              placeholder="Enter contact name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateVendor;
