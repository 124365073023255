import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input, message, Modal } from 'antd';

import { gameCreate } from '@src/clients/3thix';
import { CreateGameRequest, Error3thix, SuccessGameCreate } from '@src/types';

type Props = {
  publisherId: string;
  toggleOpen: () => void;
  isOpen: boolean;
};

const ModalCreateGame = ({ toggleOpen, isOpen, publisherId }: Props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<CreateGameRequest>({
    publisher_id: publisherId,
    name: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await gameCreate(formData);
      if (status === 201) {
        navigate(`/publishers/${publisherId}/games/${(data as SuccessGameCreate).game_id}`);
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, navigate, publisherId]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-building"></i> New Game
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Name
            </label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              defaultValue={formData.name}
              placeholder="Game Studio SA"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateGame;
