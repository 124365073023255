import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { userRoleList } from '@src/clients/3thix';
import { Error3thix, SuccessUserRoleList, UserRole } from '@src/types';

import ModalGrantUserRole from './components/ModalGrantUserRole';
import ModalRevokeUserRole from './components/ModalRevokeUserRole';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  parentEntityID: string | null;
  entityID: string | null;
};

const UserRolesTable = ({ parentEntityID, entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openGrantModal, setOpenGrantModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [rolesResp, setRolesResp] = useState<SuccessUserRoleList | null>(null);
  const [selectedRole, setSelectedRole] = useState<UserRole | null>(null);
  const [openRevokeModal, setOpenRevokeModal] = useState(false);

  const getRoles = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await userRoleList(parentEntityID, entityID, page, pageSize);

    if (status === 200) {
      setRolesResp(data as SuccessUserRoleList);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    message.error((data as Error3thix).message);
  }, [page, pageSize, parentEntityID, entityID]);

  const toggleGrantModal = () => setOpenGrantModal((prev) => !prev);
  const toggleRevokeModal = useCallback(() => {
    setOpenRevokeModal((prev) => !prev);
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const refreshRoles = useCallback(() => {
    getRoles();
  }, [getRoles]);

  const rows = useMemo(() => {
    if (rolesResp === null) {
      return [];
    }

    return rolesResp.user_roles;
  }, [rolesResp]);

  const columns = useMemo(() => {
    const columnsItens = [...COLUMNS];

    const actionsColumn = {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (_: unknown, record: UserRole) => (
        <Flex align="flex-start" gap="small">
          <Tooltip title="Revoke role">
            <Button
              type="default"
              size="middle"
              icon={<i className="fas fa-trash" />}
              onClick={() => {
                setSelectedRole(record);
                toggleRevokeModal();
              }}
            />
          </Tooltip>
        </Flex>
      ),
    };

    columnsItens.unshift(actionsColumn);

    return columnsItens;
  }, [setSelectedRole, toggleRevokeModal]);

  return (
    <div className="text-right">
      {parentEntityID && (
        <Button
          type="default"
          size="large"
          icon={<i className="fas fa-plus" />}
          style={{ marginBottom: '20px' }}
          onClick={toggleGrantModal}
        >
          Grant User Role
        </Button>
      )}

      <ModalGrantUserRole
        parentEntityID={parentEntityID as string}
        entityID={entityID}
        isOpen={openGrantModal}
        toggleOpen={toggleGrantModal}
        onRefresh={refreshRoles}
      />

      <ModalRevokeUserRole
        userRole={selectedRole as UserRole}
        isOpen={openRevokeModal}
        toggleOpen={toggleRevokeModal}
        onRefresh={getRoles}
      />

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && rolesResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: rolesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserRolesTable;
