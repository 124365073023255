import { useCallback, useMemo, useState } from 'react';

import { App, Button, message, Modal, Select } from 'antd';
import Input from 'antd/es/input/Input';

import { campaignGameUpdate } from '@src/clients/3thix';
import { CURRENCY_DESCRIPTIONS } from '@src/constants';
import { Error3thix, UpdateCampaignGameReq } from '@src/types';

type Props = {
  old: UpdateCampaignGameReq;
  toggleOpen: () => void;
  onSuccess: () => void;
  isOpen: boolean;
};

function convertToLocalTime(date: string | null): string {
  return date ? date.substring(0, 16) : '';
}

const ModalUpdate = ({ toggleOpen, isOpen, onSuccess, old }: Props) => {
  const { modal } = App.useApp();
  const [formData, setFormData] = useState<UpdateCampaignGameReq>(old);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await campaignGameUpdate(formData);
      if (status === 204) {
        modal.success({
          title: 'Campaign successfully updated',
          onOk: onSuccess,
          footer: (_, { OkBtn }) => <OkBtn />,
        });
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, modal, onSuccess]
  );

  const inputs = useMemo(() => {
    function input(name: string) {
      switch (name) {
        case 'start_date':
        case 'end_date':
          return (
            <Input
              size="large"
              type="datetime-local"
              name={name}
              defaultValue={convertToLocalTime(old[name])}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                //2024-09-12T20:13\" as \"2006-01-02T15:04:05Z07:00
                const parsedValue = value + ':00Z';
                setFormData((prevFormData) => ({ ...prevFormData, [name]: parsedValue }));
              }}
            />
          );
        case 'max_redemption_per_campaign':
        case 'max_redemption_per_user':
          return (
            <Input
              required
              size="large"
              type="number"
              min={old[name]}
              name={name}
              defaultValue={old[name]}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                setFormData((prevFormData) => ({ ...prevFormData, [name]: Number(value) }));
              }}
            />
          );
        case 'source_account_id':
          return (
            <Input
              aria-required
              size="large"
              placeholder="Account ID or Keep empty to use root minter account"
              name="source_account_id"
              value={formData.source_account_id || ''}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setFormData((prevFormData) => ({ ...prevFormData, source_account_id: value }));
              }}
            />
          );
        case 'currency':
          return (
            <Select
              size="large"
              placeholder="Select Currency Minter"
              value={formData.currency}
              className="my-2 w-full"
              onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))}
            >
              {Object.keys(CURRENCY_DESCRIPTIONS).map((key) => (
                <Select.Option key={key} value={key}>
                  {CURRENCY_DESCRIPTIONS[key]}
                </Select.Option>
              ))}
            </Select>
          );
        default:
          return (
            <Input
              required
              size="large"
              type="text"
              name={name}
              defaultValue={old[name]}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
              }}
            />
          );
      }
    }

    return Object.keys(old)
      .filter((key) => key !== 'id')
      .map((key) => (
        <div key={`index-${key}`} className="flex flex-col w-[48%]">
          <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
            {key.replaceAll('_', ' ')}
          </label>
          {input(key)}
        </div>
      ));
  }, [formData, old]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={700}
      title={
        <>
          <i className="fa-solid fa-money-bill-transfer"></i> Edit Campaign
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col">
            <div className="flex flex-row w-full flex-wrap justify-between">{inputs}</div>
          </div>

          <Button size="large" className="mt-8 w-full" htmlType="submit">
            Update
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalUpdate;
