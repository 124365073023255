import { useCallback, useEffect, useMemo, useState } from 'react';

import { App, Button, Input, message, Modal, Select } from 'antd';
import Title from 'antd/es/typography/Title';

import { transactionsLimitsList, transactionsLimitsSet } from '@src/clients/3thix';
import { Error3thix, SuccessTransactionsLimitsList, TransactionLimits } from '@src/types';

type Props = {
  toggleOpen: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  id: string;
};

const defaultValue = {
  first_month_daily_amount: '',
  first_month_daily_qty: '',
  first_month_weekly_amount: '',
  first_month_weekly_qty: '',
  first_month_amount: '',
  first_month_qty: '',
  default_daily_amount: '',
  default_daily_qty: '',
  default_week_amount: '',
  default_week_qty: '',
  default_month_amount: '',
  default_month_qty: '',
};

const ModalCreate = ({ id, toggleOpen, isOpen, onSuccess }: Props) => {
  const { modal } = App.useApp();
  const [transactionsLimitsSelected, setTransactionsLimitsSelected] = useState<TransactionLimits>();
  const [transactionsLimits, setTransactionsLimits] = useState<SuccessTransactionsLimitsList>();

  const listAll = useCallback(async (name?: string) => {
    const { data, status } = await transactionsLimitsList(1, 5, name);
    if (status === 200) {
      setTransactionsLimits(data as SuccessTransactionsLimitsList);
      return;
    }

    message.error((data as Error3thix).message);
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      if (!transactionsLimitsSelected) return;
      event.preventDefault();

      const { status, data } = await transactionsLimitsSet(id, transactionsLimitsSelected.id);
      if (status === 201) {
        modal.success({
          title: 'Transaction limits successfully set',
          onOk: onSuccess,
          footer: (_, { OkBtn }) => <OkBtn />,
        });
        return;
      }

      setTransactionsLimitsSelected(undefined);
      message.error((data as Error3thix).message);
    },
    [id, modal, onSuccess, transactionsLimitsSelected]
  );

  useEffect(() => {
    listAll(undefined);
  }, [listAll]);

  const inputs = useMemo(() => {
    if (!transactionsLimitsSelected) return null;

    return Object.keys(defaultValue).map((key) => (
      <div key={`index-${key}`} className="flex flex-col w-[48%]">
        <label className="text-white font-[10px]" htmlFor="country">
          {key.replaceAll('_', ' ')}
        </label>
        <Input
          required
          disabled
          size="large"
          name="name"
          value={transactionsLimitsSelected[key]}
          className="my-2 w-full"
        />
      </div>
    ));
  }, [transactionsLimitsSelected]);

  const selectInput = useMemo(() => {
    const options = transactionsLimits
      ? transactionsLimits.transactions_limits.map((transactionLimits, index) => ({
          label: transactionLimits.name,
          value: index,
        }))
      : [];

    return (
      <Select<string>
        showSearch
        size="large"
        placeholder="Select a transaction limit template"
        optionFilterProp="label"
        className="my-2 w-full"
        options={options}
        onSearch={(value) => listAll(value)}
        onChange={(value) => setTransactionsLimitsSelected(transactionsLimits?.transactions_limits[value])}
      />
    );
  }, [listAll, transactionsLimits]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-money-bill-transfer"></i> Set Transaction limit
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col">
            <label className="text-white font-[10px]">Transaction Limits</label>
            {selectInput}
          </div>

          {transactionsLimitsSelected && (
            <div className="mt-8">
              <Title level={4}>Preview</Title>
              <div className="mt-4 flex flex-row w-full flex-wrap justify-between">{inputs}</div>
            </div>
          )}

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-check" />}>
            Set
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreate;
