export const DEFAULT_SEARCH_VALUES = {
  issuer_entity_id: null,
  type: null,
  destination_rail: null,
  destination_currency: null,
  status: null,
  order_by: null,
  receiver_name: null,
  receiver_email: null,
  payer_name: null,
  payer_email: null,
};

export const PAGE_SIZE = 10;
