import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';

import { addressList } from '@src/clients/3thix';
import { Error3thix, SuccessAddressesList } from '@src/types';

import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  entityID: string;
};

const AddressesTable = ({ entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [addressesResp, setAddressesResp] = useState<SuccessAddressesList | null>(null);

  const getAddresses = useCallback(async () => {
    const { data, status } = await addressList(entityID, page, pageSize);

    if (status === 200) {
      setAddressesResp(data as SuccessAddressesList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [entityID, page, pageSize]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const rows = useMemo(() => {
    if (addressesResp === null) {
      return [];
    }

    return addressesResp.addresses;
  }, [addressesResp]);

  return (
    <div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && addressesResp !== null && (
          <Table
            dataSource={rows}
            columns={COLUMNS}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: addressesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AddressesTable;
