import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { notificationEmailConfigsEnableToggle, notificationEmailConfigsList } from '@src/clients/3thix';
import { searchRangeDateFilterTable, searchTextFilterTable } from '@src/components/SearchFilterTable';
import { EmailConfig, Error3thix, ListNotificationEmailConfigsReq, ListNotificationEmailConfigsResp } from '@src/types';

import ModalCreate from './components/ModalCreate';
import ModalUpdate from './components/ModalUpdate';
import { DEFAULT_SEARCH_VALUES, PAGE_SIZE } from './constants';

const Emails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<{ id: string; oldDestinationEmail: string }>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [emailConfigsResp, setEmailConfigsResp] = useState<ListNotificationEmailConfigsResp>();
  const [search, setSearch] = useState<ListNotificationEmailConfigsReq>({ search_by: DEFAULT_SEARCH_VALUES });

  const toggleCreateOpen = useCallback(() => setOpenCreateModal((old) => !old), []);

  const getAudits = useCallback(async () => {
    const { data, status } = await notificationEmailConfigsList(page, pageSize, search);

    if (status === 200) {
      setEmailConfigsResp(data as ListNotificationEmailConfigsResp);
    } else {
      message.error((data as Error3thix).message);
    }

    setIsLoading(false);
  }, [page, pageSize, search]);

  const refresh = useCallback(() => {
    getAudits();
    setOpenCreateModal(false);
    setUpdateModal(undefined);
  }, [getAudits]);

  const onChangeSort = useCallback((field: string) => {
    setSearch((old) => {
      if (old.order_by && old.order_by.field === field) {
        return { ...old, order_by: old.order_by.order === 'DESC' ? { field, order: 'ASC' } : undefined };
      }

      return { ...old, order_by: { field, order: 'DESC' } };
    });
  }, []);

  useEffect(() => {
    getAudits();
  }, [getAudits]);

  const toggleEnable = useCallback(
    async (id, enabled) => {
      await notificationEmailConfigsEnableToggle(id, enabled ? 'disable' : 'enable');
      refresh();
    },
    [refresh]
  );

  const rows = useMemo(() => (emailConfigsResp ? emailConfigsResp.email_configs : []), [emailConfigsResp]);

  const columns = useMemo(
    () => [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: EmailConfig) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Enable/Disable">
              <Button
                type="default"
                size="middle"
                icon={<i className="fa-solid fa-power-off"></i>}
                onClick={() => toggleEnable(record.id, record.enabled)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setUpdateModal({ id: record.id, oldDestinationEmail: record.destination_email });
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('enabled');
          },
        }),
        render: (enabled: boolean) => {
          return enabled ? 'Yes' : 'No';
        },
      },
      {
        title: 'Event',
        dataIndex: 'notification_event',
        filterDropdown: searchTextFilterTable({
          setSearchText: (notification_event) =>
            setSearch((old) => {
              old.search_by.notification_event = notification_event === null ? undefined : notification_event;
              return { ...old };
            }),
          fieldName: 'Event',
        }),
      },
      {
        title: 'Destination',
        dataIndex: 'destination_email',
        filterDropdown: searchTextFilterTable({
          setSearchText: (destination_email) =>
            setSearch((old) => {
              old.search_by.destination_email = destination_email === null ? undefined : destination_email;
              return { ...old };
            }),
          fieldName: 'Destination',
        }),
      },
      {
        title: 'Entity Name',
        dataIndex: 'entity_name',
        filterDropdown: searchTextFilterTable({
          setSearchText: (entity_name) =>
            setSearch((old) => {
              old.search_by.entity_name = entity_name === null ? undefined : entity_name;
              return { ...old };
            }),
          fieldName: 'Entity Name',
        }),
      },
      {
        title: 'Entity Type',
        dataIndex: 'entity_type',
        filterDropdown: searchTextFilterTable({
          setSearchText: (entity_type) =>
            setSearch((old) => {
              old.search_by.entity_type = entity_type === null ? undefined : entity_type;
              return { ...old };
            }),
          fieldName: 'Entity Type',
        }),
      },
      { title: 'ID', dataIndex: 'id' },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        render: (created_at: string) => {
          return new Date(created_at).toDateString();
        },
        filterDropdown: searchRangeDateFilterTable({
          setRange: (value) => setSearch((old) => ({ ...old, date_range: value })),
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('created_at');
          },
        }),
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        render: (updated_at: string) => {
          return new Date(updated_at).toDateString();
        },
        filterDropdown: searchRangeDateFilterTable({
          setRange: (value) => setSearch((old) => ({ ...old, date_range: value })),
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('updated_at');
          },
        }),
      },
    ],
    [onChangeSort, toggleEnable]
  );

  return (
    <>
      <div>{isLoading && <Skeleton />}</div>

      <div className="full-width text-right mb-8 mt-8">
        <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleCreateOpen}>
          New Email Config
        </Button>
      </div>

      <div>
        {!isLoading && emailConfigsResp && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: emailConfigsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>

      <ModalCreate isOpen={openCreateModal} toggleOpen={toggleCreateOpen} onSuccess={refresh} />
      <ModalUpdate updateModal={updateModal} close={() => setUpdateModal(undefined)} onSuccess={refresh} />
    </>
  );
};

export default Emails;
