import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Switch, Table } from 'antd';

import { apiKeysDeactivated, apiKeysListByGame } from '@src/clients/3thix';
import { ApiKey, Error3thix } from '@src/types';

import ModalCreate from './components/ModalCreate';

type Props = {
  gameId: string;
};

const ApiKeysTable = ({ gameId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiKeysResp, setApiKeysResp] = useState<ApiKey[] | null>(null);

  const getApiKeys = useCallback(async () => {
    setIsLoading(true);

    const { data, status } = await apiKeysListByGame(gameId);

    if (status === 200) {
      setApiKeysResp(data as ApiKey[]);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [gameId]);

  const changeStatus = useCallback(
    (id: string) => {
      return async () => {
        const { data, status } = await apiKeysDeactivated(id);

        if (status === 200) {
          getApiKeys();
          return;
        }

        message.error((data as Error3thix).message);
      };
    },
    [getApiKeys]
  );

  const formatApiKeysResp = useMemo(() => {
    if (!apiKeysResp) {
      return [];
    }

    return apiKeysResp.map((value) => ({
      id: value.id,
      first_chars: value.first_chars,
      last_chars: value.last_chars,
      status: value.active ? <Switch defaultChecked onChange={changeStatus(value.id)} /> : <Switch disabled />,
      created_at: new Date(value.created_at).toDateString(),
      updated_at: new Date(value.updated_at).toDateString(),
    }));
  }, [apiKeysResp, changeStatus]);

  useEffect(() => {
    getApiKeys();
  }, [getApiKeys]);

  return (
    <div className="text-right">
      <ModalCreate gameId={gameId} onSuccess={getApiKeys} />

      <div>{isLoading && <Skeleton />}</div>

      <div>
        {!isLoading && apiKeysResp !== null && (
          <Table
            dataSource={formatApiKeysResp}
            columns={[
              { title: 'First Chars', dataIndex: 'first_chars' },
              { title: 'Last Chars', dataIndex: 'last_chars' },
              { title: 'Status', dataIndex: 'status' },
              {
                title: 'Created at',
                dataIndex: 'created_at',
                render: (created_at: string) => {
                  return new Date(created_at).toDateString();
                },
              },
              {
                title: 'Updated at',
                dataIndex: 'updated_at',
                render: (updated_at: string) => {
                  return new Date(updated_at).toDateString();
                },
              },
            ]}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: 1,
              total: 2,
              pageSize: 10,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ApiKeysTable;
