export const PAGE_SIZE = 20;

export const COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Currency', dataIndex: 'currency' },
  { title: 'First month daily amount', dataIndex: 'first_month_daily_amount' },
  { title: 'First month daily qty', dataIndex: 'first_month_daily_qty' },
  { title: 'First month weekly amount', dataIndex: 'first_month_weekly_amount' },
  { title: 'First month weekly qty', dataIndex: 'first_month_weekly_qty' },
  { title: 'First month amount', dataIndex: 'first_month_amount' },
  { title: 'First month qty', dataIndex: 'first_month_qty' },
  { title: 'Default daily amount', dataIndex: 'default_daily_amount' },
  { title: 'Default daily qty', dataIndex: 'default_daily_qty' },
  { title: 'Default week amount', dataIndex: 'default_week_amount' },
  { title: 'Default week qty', dataIndex: 'default_week_qty' },
  { title: 'Default month amount', dataIndex: 'default_month_amount' },
  { title: 'Default month qty', dataIndex: 'default_month_qty' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];
