export const COLUMNS = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Type', dataIndex: 'processor_type' },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Config',
    dataIndex: 'config',
    render: ({ merchant_id, merchant_site_id }) => {
      return `Merchant ID: ${merchant_id} | Merchant Site ID: ${merchant_site_id}`;
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];
