import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message, Skeleton, Table } from 'antd';
import Title from 'antd/es/typography/Title';

import { allOrdersList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { searchRangeDateFilterTable, searchTextFilterTable } from '@src/components/SearchFilterTable';
import { Error3thix, SearchOrderReq, SuccessOrdersList } from '@src/types';

import { DEFAULT_SEARCH_VALUES, PAGE_SIZE } from './constants';

const Orders = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [ordersResp, setOrdersResp] = useState<SuccessOrdersList | null>(null);
  const [search, setSearch] = useState<SearchOrderReq>(DEFAULT_SEARCH_VALUES);

  const getOrders = useCallback(async () => {
    const { data, status } = await allOrdersList(page, pageSize, search);

    if (status === 200) {
      setOrdersResp(data as SuccessOrdersList);
    } else {
      message.error((data as Error3thix).message);
    }

    setIsLoading(false);
  }, [page, pageSize, search]);

  const onChangeSort = useCallback((field: string) => {
    setSearch((old) => {
      if (old.order_by !== null && old.order_by.field === field) {
        return { ...old, order_by: old.order_by.order === 'DESC' ? { field, order: 'ASC' } : null };
      }

      return { ...old, order_by: { field, order: 'DESC' } };
    });
  }, []);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const rows = useMemo(() => (ordersResp ? ordersResp.orders : []), [ordersResp]);

  const columns = useMemo(
    () => [
      {
        title: 'Publisher',
        dataIndex: 'publisher_name',
        filterDropdown: searchTextFilterTable({
          setSearchText: (publisher_name) => setSearch((old) => ({ ...old, publisher_name })),
          fieldName: 'Publisher name',
        }),
      },
      {
        title: 'Issuer',
        dataIndex: 'issuer_name',
        filterDropdown: searchTextFilterTable({
          setSearchText: (issuer_name) => setSearch((old) => ({ ...old, issuer_name })),
          fieldName: 'Issuer name',
        }),
      },
      {
        title: 'Receiver',
        dataIndex: 'receiver_name',
        filterDropdown: searchTextFilterTable({
          setSearchText: (receiver_name) => setSearch((old) => ({ ...old, receiver_name })),
          fieldName: 'User name',
        }),
      },
      {
        title: 'Receiver Email',
        dataIndex: 'receiver_email',
        filterDropdown: searchTextFilterTable({
          setSearchText: (receiver_email) => setSearch((old) => ({ ...old, receiver_email })),
          fieldName: 'User Email',
        }),
      },
      {
        title: 'Payer',
        dataIndex: 'payer_name',
        filterDropdown: searchTextFilterTable({
          setSearchText: (payer_name) => setSearch((old) => ({ ...old, payer_name })),
          fieldName: 'User name',
        }),
      },
      {
        title: 'Payer Email',
        dataIndex: 'payer_email',
        filterDropdown: searchTextFilterTable({
          setSearchText: (payer_email) => setSearch((old) => ({ ...old, payer_email })),
          fieldName: 'User Email',
        }),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        filterDropdown: searchTextFilterTable({
          setSearchText: (type) => setSearch((old) => ({ ...old, type })),
          fieldName: 'Type',
        }),
      },
      {
        title: 'Currency',
        dataIndex: 'destination_currency',
      },
      {
        title: 'Amount',
        dataIndex: 'destination_amount',
      },
      { title: 'Fee', dataIndex: 'destination_fees' },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        render: (created_at: string) => {
          return new Date(created_at).toDateString();
        },
        sorter: true,
        filterDropdown: searchRangeDateFilterTable({
          setRange: (value) => setSearch((old) => ({ ...old, date_range: value })),
        }),
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('created_at');
          },
        }),
      },
    ],
    [onChangeSort]
  );

  const onRow = useCallback(
    (record: { id: string }) => ({
      onClick: () => {
        navigate(`/orders/${record.id}`);
      },
    }),
    [navigate]
  );

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <Title level={2}>
          <i className="fa-solid fa-receipt"></i> Orders
        </Title>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && ordersResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: ordersResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
            onRow={onRow}
          />
        )}
      </div>
    </Container>
  );
};

export default Orders;
