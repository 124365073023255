import { useCallback, useEffect, useMemo, useState } from 'react';

// import { Button, Flex, message, Skeleton, Table, Tag, Tooltip } from 'antd';
import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { feePolicyList } from '@src/clients/3thix';
// import { Error3thix, FeePolicy, SuccessFeeList } from '@src/types';
import { Error3thix, FeePolicy, SuccessFeePolicyList } from '@src/types';

import ModalCreateFee from './components/ModalCreateFee';
import ModalUpdateFee from './components/ModalUpdateFee';
import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  entityID: string;
};

const FeesTable = ({ entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [feesResp, setFeesResp] = useState<SuccessFeePolicyList | null>(null);
  const [selectedFee, setSelectedFee] = useState<FeePolicy | null>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const getFees = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await feePolicyList(entityID, page, pageSize);

    if (status === 200) {
      setFeesResp(data as SuccessFeePolicyList);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    message.error((data as Error3thix).message);
  }, [page, pageSize, entityID]);

  const toggleCreateModal = () => setOpenCreateModal((prev) => !prev);
  const toggleUpdateModal = useCallback(() => {
    setOpenUpdateModal((prev) => !prev);
  }, []);

  useEffect(() => {
    getFees();
  }, [getFees]);

  const refreshFees = useCallback(() => {
    getFees();
  }, [getFees]);

  const rows = useMemo(() => {
    if (feesResp === null) {
      return [];
    }

    return feesResp.fee_policies;
  }, [feesResp]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: FeePolicy) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setSelectedFee(record);
                  toggleUpdateModal();
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      ...COLUMNS,
    ];
  }, [setSelectedFee, toggleUpdateModal]);

  return (
    <div className="text-right">
      <Button
        type="default"
        size="large"
        icon={<i className="fas fa-plus" />}
        style={{ marginBottom: '20px' }}
        onClick={toggleCreateModal}
      >
        New Fee Policy
      </Button>
      <ModalCreateFee
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onRefresh={refreshFees}
      />

      <ModalUpdateFee
        feePolicy={selectedFee}
        isOpen={openUpdateModal}
        toggleOpen={toggleUpdateModal}
        onRefresh={getFees} // Assuming getFees is your function to refresh the list
      />

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && feesResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: feesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FeesTable;
