import { useCallback, useState } from 'react';

import { Button, Input, message, Modal, Select } from 'antd';

import { feePolicyCreate } from '@src/clients/3thix';
import { CURRENCY_DESCRIPTIONS, RAIL_CURRENCY_MAP } from '@src/constants';
import { Error3thix } from '@src/types';

type Props = {
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalCreateFee = ({ entityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState({
    entity_id: entityID,
    rail: 'ETHEREUM',
    currency: 'ETH',
    buyer_to_seller_amount_percentage: '0.0',
    buyer_to_seller_amount_fixed: '0.0',
    buyer_to_house_amount_percentage: '0.0',
    buyer_to_house_amount_fixed: '0.0',
    seller_to_house_amount_percentage: '0.0',
    seller_to_house_amount_fixed: '0.0',
    pay_network: 'SELLER',
  });

  const availableCurrencies = RAIL_CURRENCY_MAP[formData.rail as keyof typeof RAIL_CURRENCY_MAP] || [];

  const handleChangeSelect = useCallback((value: string, fieldName: string) => {
    if (fieldName === 'rail') {
      const currencies = RAIL_CURRENCY_MAP[value as keyof typeof RAIL_CURRENCY_MAP];
      const defaultCurrency = currencies[0];
      setFormData((old) => ({
        ...old,
        rail: value,
        currency: defaultCurrency,
      }));
    } else if (fieldName === 'currency' || fieldName === 'pay_network') {
      setFormData((old) => ({ ...old, [fieldName]: value }));
    }
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((old) => ({ ...old, [name]: value }));
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { status, data } = await feePolicyCreate(formData);

    if (status === 201) {
      onRefresh();
      toggleOpen();
      return;
    }

    const errorMessage = (data as Error3thix).details
      ? `${(data as Error3thix).message}: ${(data as Error3thix).details}`
      : (data as Error3thix).message;
    message.error(errorMessage);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-coins"></i> New Fee Policy
        </>
      }
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="rail">
              Rail
            </label>
            <Select<string>
              size="large"
              value={formData.rail}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'rail')}
            >
              {Object.keys(RAIL_CURRENCY_MAP).map((rail) => (
                <Select.Option key={rail} value={rail}>
                  {rail}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="currency">
              Currency
            </label>
            <Select
              size="large"
              value={formData.currency}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'currency')}
            >
              {availableCurrencies
                .filter((currency): currency is string => typeof currency === 'string')
                .map((currency) => (
                  <Select.Option key={currency} value={currency}>
                    {CURRENCY_DESCRIPTIONS[currency]}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToSellerAmountPercentage">
              Buyer to Seller Amount Percentage
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_seller_amount_percentage"
              defaultValue={formData.buyer_to_seller_amount_percentage}
              placeholder="Buyer to Seller Amount Percentage"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToSellerAmountFixed">
              Buyer to Seller Amount Fixed
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_seller_amount_fixed"
              defaultValue={formData.buyer_to_seller_amount_fixed}
              placeholder="Buyer to Seller Amount Fixed"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToHouseAmountPercentage">
              Buyer to House Amount Percentage
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_house_amount_percentage"
              defaultValue={formData.buyer_to_house_amount_percentage}
              placeholder="Buyer to House Amount Percentage"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToHouseAmountFixed">
              Buyer to House Amount Fixed
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_house_amount_fixed"
              defaultValue={formData.buyer_to_house_amount_fixed}
              placeholder="Buyer to House Amount Fixed"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="sellerToHouseAmountPercentage">
              Seller to House Amount Percentage
            </label>
            <Input
              size="large"
              type="text"
              name="seller_to_house_amount_percentage"
              defaultValue={formData.seller_to_house_amount_percentage}
              placeholder="Seller to House Amount Percentage"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="sellerToHouseAmountFixed">
              Seller to House Amount Fixed
            </label>
            <Input
              size="large"
              type="text"
              name="seller_to_house_amount_fixed"
              defaultValue={formData.seller_to_house_amount_fixed}
              placeholder="Seller to House Amount Fixed"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="payNetwork">
              Pay Network
            </label>
            <Select
              size="large"
              value={formData.pay_network}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'pay_network')}
            >
              <Select.Option value="SELLER">SELLER</Select.Option>
              <Select.Option value="BUYER">BUYER</Select.Option>
              <Select.Option value="HOUSE">HOUSE</Select.Option>
            </Select>
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create Fee Policy
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateFee;
