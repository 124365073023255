import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';
import Title from 'antd/es/typography/Title';

import { auditList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { searchRangeDateFilterTable, searchTextFilterTable } from '@src/components/SearchFilterTable';
import { Error3thix, ListAuditRequest, SuccessAuditList } from '@src/types';

import { DEFAULT_SEARCH_VALUES, PAGE_SIZE } from './constants';

const Audit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [auditResp, setAuditResp] = useState<SuccessAuditList>();
  const [search, setSearch] = useState<ListAuditRequest>({ search_by: DEFAULT_SEARCH_VALUES });

  const getAudits = useCallback(async () => {
    const { data, status } = await auditList(page, pageSize, search);

    if (status === 200) {
      setAuditResp(data as SuccessAuditList);
    } else {
      message.error((data as Error3thix).message);
    }

    setIsLoading(false);
  }, [page, pageSize, search]);

  const onChangeSort = useCallback((field: string) => {
    setSearch((old) => {
      if (old.order_by && old.order_by.field === field) {
        return { ...old, order_by: old.order_by.order === 'DESC' ? { field, order: 'ASC' } : undefined };
      }

      return { ...old, order_by: { field, order: 'DESC' } };
    });
  }, []);

  useEffect(() => {
    getAudits();
  }, [getAudits]);

  const rows = useMemo(() => (auditResp ? auditResp.audits : []), [auditResp]);

  const columns = useMemo(
    () => [
      { title: 'ID', dataIndex: 'id' },
      {
        title: 'Auth ID',
        dataIndex: 'auth_id',
        filterDropdown: searchTextFilterTable({
          setSearchText: (auth_id) =>
            setSearch((old) => {
              old.search_by.auth_id = auth_id === null ? undefined : auth_id;
              return { ...old };
            }),
          fieldName: 'Auth ID',
        }),
      },
      {
        title: 'Event Name',
        dataIndex: 'event_name_e',
        filterDropdown: searchTextFilterTable({
          setSearchText: (event_name_e) =>
            setSearch((old) => {
              old.search_by.event_name_e = event_name_e === null ? undefined : event_name_e;
              return { ...old };
            }),
          fieldName: 'Event Name',
        }),
      },
      {
        title: 'Object Type',
        dataIndex: 'object_type_e',
        filterDropdown: searchTextFilterTable({
          setSearchText: (object_type_e) =>
            setSearch((old) => {
              old.search_by.object_type_e = object_type_e === null ? undefined : object_type_e;
              return { ...old };
            }),
          fieldName: 'Object Type',
        }),
      },
      {
        title: 'Object ID',
        dataIndex: 'object_id',
        filterDropdown: searchTextFilterTable({
          setSearchText: (object_id) =>
            setSearch((old) => {
              old.search_by.object_id = object_id === null ? undefined : object_id;
              return { ...old };
            }),
          fieldName: 'Object ID',
        }),
      },
      {
        title: 'From',
        dataIndex: 'from',
        render: (from: string) => {
          return JSON.stringify(from);
        },
      },
      {
        title: 'To',
        dataIndex: 'to',
        render: (to: string) => {
          return JSON.stringify(to);
        },
      },
      {
        title: 'Request',
        dataIndex: 'request',
        render: (request: string) => {
          return JSON.stringify(request);
        },
      },
      {
        title: 'IP Address',
        dataIndex: 'ip_address',
        filterDropdown: searchTextFilterTable({
          setSearchText: (ip_address) =>
            setSearch((old) => {
              old.search_by.ip_address = ip_address === null ? undefined : ip_address;
              return { ...old };
            }),
          fieldName: 'IP Address',
        }),
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        render: (created_at: string) => {
          return new Date(created_at).toDateString();
        },
        sorter: true,
        filterDropdown: searchRangeDateFilterTable({
          setRange: (value) => setSearch((old) => ({ ...old, date_range: value })),
        }),
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('created_at');
          },
        }),
      },
    ],
    [onChangeSort]
  );

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <Title level={2}>
          <i className="fa-solid fa-receipt"></i> Audit
        </Title>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && auditResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: auditResp?.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default Audit;
