import { useParams } from 'react-router-dom';

import { Tabs } from 'antd';
import Title from 'antd/es/typography/Title';

import Container from '@src/components/Container';

import Fuifillments from './components/FulfillmentsTable';
import InvoicesTable from './components/InvoicesTable';

const Order = () => {
  const { id } = useParams();

  return (
    <Container
      breadcrumbs={[
        { name: 'Orders', path: '/orders' },
        { name: 'Order', path: `/orders/${id}` },
      ]}
    >
      <Title level={2}>Order</Title>
      <Title level={5}>Order ID: {id}</Title>
      <Tabs
        centered
        defaultActiveKey="1"
        items={[
          {
            label: 'Invoices',
            key: '1',
            children: <InvoicesTable orderId={id as string} />,
          },
          {
            label: 'Fulfillments',
            key: '2',
            children: <Fuifillments orderId={id as string} />,
          },
        ]}
      />
    </Container>
  );
};

export default Order;
